import React, {useEffect, useState} from 'react';
import {
    Button,
    Form,
    Input,
    Modal,
    Space,
    message, Popconfirm, Select
} from "antd";
import PhocusTable from "../../components/PhocusTable/PhocusTable";
import RestClient from "../../Controller/rest/RestClient";
import {rootServiceURL} from "../../Controller";
import {IField} from "../../interfaces/IField";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {deleteSurvey} from "./actions";
import {ISurvey} from "../../interfaces/ISurvey";


export default function SurveyList(){

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // @ts-ignore
    const auth = useSelector(state => state.auth);
    const [list, setList] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updateFieldValues, setUpdateFieldValues] = useState<ISurvey | undefined>(undefined);

    useEffect(() => {
        loadSurveyList();

        return () => {
        };
    }, []);

    const showModal = () => {
        setIsModalOpen(true);
    };

    function loadSurveyList () {
        const token = auth.token;

        const restClient = new RestClient(dispatch)
            .token(token).url(rootServiceURL() +"phocusservice/survey");
        restClient.doGet((result: []) => {
            result && setList(result)
        });

    }

    const columns : IField<ISurvey>[]=
        [
            {
                title: 'Código',
                dataIndex: 'surveyid',
                key: 'surveyid',
                show:false,
                update:false,
                sorter: (a, b) => a.surveyid > b.surveyid ? 1 : -1,
            },
            {
                title: 'Título',
                dataIndex: 'surveyTitle',
                key: 'surveyTitle',
                show:true,
                update:true,
                rules: [{ required: true, message: 'Please input a title' }],
                sorter: (a, b) => a.surveyTitle > b.surveyTitle ? 1 : -1,
            },
            {
                title: 'Actions',
                key: 'actions',
                render: (value, record) => {
                    return (
                        <Space size="middle">
                            <a onClick={() => openUpdateModal(record)
                            }>Editar </a>
                            <a onClick={() => navigate("/blocks/"+record.surveyid)
                            }>Bloques </a>
                            <Popconfirm
                                placement="left"
                                title="Eliminar Encuesta"
                                description={<>
                                    <p>
                                        "¿Está seguro de que quiere eliminar esta Encuesta?"
                                    </p>

                                </>}
                                onConfirm={() => {
                                    deleteSurvey(record.surveyid, auth, dispatch, () => {loadSurveyList()})
                                }}
                                onCancel={() => {}}
                                okText="Si"
                                cancelText="No"
                            >
                                <Button>Eliminar</Button>
                            </Popconfirm>
                        </Space>
                    );
                },
            }
        ];

    const openUpdateModal = (entity: ISurvey) => {
        cleanFormData();

        //@ts-ignore
        const updatedFieldValues :ISurvey= {};

        Object.keys(entity)?.forEach(field => {

                //@ts-ignore
                updatedFieldValues[field] = entity[field];

                //@ts-ignore
                form.setFieldValue(field, entity[field]);
        });
        setUpdateFieldValues(updatedFieldValues);
        setIsModalOpen(true)
    }

    const cleanFormData = () => {
        form.resetFields();
        setUpdateFieldValues(undefined);
    }

    const onFinish = (values : ISurvey) => {
        let entityToPost = {
            ...updateFieldValues,
            ...values
        };

        const url = rootServiceURL() + 'phocusservice/survey';

        const token = auth.token;

        const rc = new RestClient(dispatch).token(token).url(url);

        const update = updateFieldValues && updateFieldValues!.surveyid !== undefined && updateFieldValues!.surveyid > 0;
        if(!update){
            console.log("Creando Encuesta: " ,entityToPost)

            rc.doPost(entityToPost, (result: any) => {
                if (rc.isSuccess()) {
                    message.success("Encuesta creada correctamente")
                    loadSurveyList();
                    setIsModalOpen(false);
                    cleanFormData();
                }
                else{
                    message.error( "Error creando Encuesta "+rc.getErrors());
                }

            }, false);

        } else {
            rc.doPost(entityToPost, (result: any) => {
                if (rc.isSuccess()) {
                    message.success("Encuesta actualizada correctamente");
                    loadSurveyList();
                    setIsModalOpen(false);
                    cleanFormData();
                }
                else{
                    message.error( "Error actualizando Encuesta "+rc.getErrors());
                }

            }, false);
        }
    }

    return (
        <>
            <Modal
                title={"Modificar Encuesta"}
                open={isModalOpen}
                footer={null}
                onCancel={() => {
                    cleanFormData();
                    setIsModalOpen(false); }}>
                <Form
                    name="basic"
                    form={form}
                    initialValues={{ remember: false }}
                    onFinish={(values) => onFinish(values)}
                    scrollToFirstError
                    autoComplete="off">
                    {columns.filter(field =>(field.update)).map(field => (
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label={""+field.title}
                            name={field.key as string}
                            rules={field.rules}
                            valuePropName={field.valuePropName}
                        >
                            {field.renderInput ? field.renderInput(field.key as string) : <Input/>}
                        </Form.Item>
                    ))}
                    <Button type="primary" htmlType="submit">
                        Actualizar
                    </Button>
                </Form>
            </Modal>
            <Button type="primary" onClick={showModal} style={{margin: '20px'}}>
                Crear nueva Encuesta
            </Button>
            <PhocusTable columns={columns} dataSource={list} />
        </>
    );
}