import React, {PropsWithChildren, useEffect, useRef, useState} from 'react';
import {SearchOutlined,} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {Button, Input, TablePaginationConfig} from 'antd';
import type {ColumnsType, ColumnType} from 'antd/es/table';
import { InputRef, Space, Table} from 'antd';
import {FilterConfirmProps} from "antd/es/table/interface";
import styles from './PhocusTable.module.css'
import {TableProps as RcTableProps} from "rc-table/lib/Table";
interface PhocusTableProps<ObjectType> {
    dataSource: ObjectType[];
    columns : ColumnsType<ObjectType>;
    expandable? : any;
    pagination?: false | TablePaginationConfig;
    scroll?: RcTableProps<ObjectType>['scroll'] & {
        scrollToFirstRowOnChange?: boolean;
    };
    mobile?: boolean
}

export default function PhocusTable<ObjectType>({ columns, dataSource, expandable, pagination, scroll, mobile= false }: PropsWithChildren<PhocusTableProps<ObjectType>>,){
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [columnsWithSearch, setColumnsWithSearch] = useState<ColumnsType<ObjectType>[]>([]);
    const searchInput = useRef<InputRef>(null);

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: keyof ObjectType,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        // @ts-ignore
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: keyof ObjectType, column:ColumnType<ObjectType>): ColumnType<ObjectType> => ({
        // @ts-ignore
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div className={styles.filterDiv} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${String(dataIndex)}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    className={styles.inputSearch}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        className={styles.searchButton}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        className={styles.searchButton}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys as string[])[0]);
                            // @ts-ignore
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        // @ts-ignore
        onFilter: (value, record) =>
        {
            let fieldValue = record[dataIndex];
            // @ts-ignore
            if(dataIndex.includes(".")){
                // @ts-ignore
                const partialValue = record[dataIndex.split(".")[0]];
                if(partialValue){
                    // @ts-ignore
                    fieldValue = partialValue[dataIndex.split(".")[1]];
                }
            }

            // @ts-ignore
            if(column.foreignkey && column.descriptiveColumn && fieldValue){
                    // @ts-ignore
                fieldValue = fieldValue[column.descriptiveColumn];
            }

            // @ts-ignore
            return fieldValue ? fieldValue
                .toString()
                .toLowerCase()
                // @ts-ignore
                .includes((value).toLowerCase()) :false
        },
        // @ts-ignore
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text, record, index) =>{
            return (searchedColumn === dataIndex && searchText && searchText !== '' ? (
                <Highlighter
                    className={styles.highlighted}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                column && column.render ? column.render(text, record, index) : text
            ))}
    });


    useEffect(() => {
        const newColumns : ColumnsType<ObjectType>[] = [];
        columns.forEach(columm => {
            //@ts-ignore
            if(columm.show === undefined || columm.show){
                if(columm.key === "actions" || columm.key === "other"){
                    // @ts-ignore
                    newColumns.push({...columm});
                }
                else{
                    // @ts-ignore
                    newColumns.push({...columm, ...getColumnSearchProps(columm.key, columm)});
                }
            }
        })
        setColumnsWithSearch(newColumns);

        return () => {
        };
    }, [columns, searchedColumn, searchText]);

    // @ts-ignore
    return (<Table scroll={scroll} className={mobile ? "table-mobile": ""} columns={columnsWithSearch} dataSource={dataSource} expandable={expandable} pagination={pagination} size="large" />)

}
