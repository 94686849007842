import {rootServiceURL} from "../../Controller";
import RestClient from "../../Controller/rest/RestClient";
import {message} from "antd";

export function deleteQuestionGroup(questionid, auth, dispatch, callback) {
    const url = rootServiceURL() + 'phocusservice/questiongroup/'+questionid;
    deleteByUrl(auth, dispatch, url, callback);
}

export function deleteSurveyQuestion(questionid, auth, dispatch, callback) {
    const url = rootServiceURL() + 'phocusservice/surveyquestion/'+questionid;
    deleteByUrl(auth, dispatch, url, callback);
}

export function deleteAnswer(answerid, auth, dispatch, callback) {
    const url = rootServiceURL() + 'phocusservice/answer/'+answerid;
    deleteByUrl(auth, dispatch, url, callback);
}

export function deleteSurvey(surveyid, auth, dispatch, callback) {
    const url = rootServiceURL() + 'phocusservice/survey/'+surveyid;
    deleteByUrl(auth, dispatch, url, callback);
}

export function deleteBlock(blockid, auth, dispatch, callback) {
    const url = rootServiceURL() + 'phocusservice/block/'+blockid;
    deleteByUrl(auth, dispatch, url, callback);
}

function deleteByUrl(auth, dispatch, url, callback) {
    const token = auth.token;

    const rc = new RestClient(dispatch).token(token).url(url);

    rc.doDelete(result => {
        if (rc.isSuccess()) {
            callback && callback()
        } else {
            const msg = "Error elimimando registro " + rc.getErrors();
            const title = "Error";
            message.error("Error elimimando registro " + rc.getErrors())
        }
    })
}