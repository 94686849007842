import {
    REGISTRATION_DIALOG_OPEN,
    REGISTRATION_DIALOG_CLOSE,
    REGISTRATION_REQUESTED,
    REGISTRATION_OK,
    REGISTRATION_FAILED,
    REGISTRATION_FAILED_ACK,
    VERIFICATION_REQUESTED,
    VERIFICATION_OK,
    VERIFICATION_FAILED
} from './actions'

const initialState = {
    registrationRequested: false,
    registrationFailed: false,
    registrationOk: false,
    registrationError: '',
    registrationPopupOpened: false,
    verificationRequested: false,
    verificationFailed: false,
    verificationOk: false,
    verificationError: ''
}

export const registration = (state = initialState, action) => {
    switch (action.type) {
        case REGISTRATION_DIALOG_OPEN:
            return {
                ...state,
                registrationPopupOpened: true
            }
        case REGISTRATION_DIALOG_CLOSE:
            return {
                ...state,
                registrationOk: false,
                registrationPopupOpened: false
            }
        case REGISTRATION_REQUESTED:
            return {
                ...state,
                registrationRequested: true,
                registrationFailed: false,
                registrationOk: false,
                registrationError: ''
            }
        case REGISTRATION_OK:
            return {
                ...state,
                registrationRequested: false,
                registrationOk: true
            }
        case REGISTRATION_FAILED:
            return {
                ...state,
                registrationRequested: false,
                registrationOk: false,
                registrationFailed: true,
                registrationError: action.registrationError
            }
        case REGISTRATION_FAILED_ACK:
            return {
                ...initialState,
                registrationPopupOpened: true
            }
        case VERIFICATION_REQUESTED:
            return {
                ...state,
                verificationRequested: true,
                verificationFailed: false,
                verificationOk: false,
                verificationError: ''
            }
        case VERIFICATION_OK:
            return {
                ...state,
                verificationRequested: false,
                verificationOk: true
            }
        case VERIFICATION_FAILED:
            return {
                ...state,
                verificationRequested: false,
                verificationOk: false,
                verificationFailed: true,
                verificationError: action.verificationError
            }
        default:
            return state;
    }
};