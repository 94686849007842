import React, {useEffect, useState} from 'react';
import './App.css';
// @ts-ignore
import {
    BrowserRouter as Router,
    Route, RouterProvider,
    Routes, Link, useNavigate, Navigate, useLocation
} from 'react-router-dom';

import {Provider, useSelector} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "./store";
import {Button, Dropdown, Layout, Menu, MenuProps, Space, theme} from 'antd';
import LoginRegisterUser from "./pages/loginRegisterUser/LoginRegisterUser";
import {RequireAuth} from "./components/requireAuth/RequireAuth";
import AdminHeader from "./components/header/AdminHeader";
import {Content} from "antd/es/layout/layout";
import Home from "./pages/home/Home";
import HomeWithoutAuth from "./pages/home/HomeWithoutAuth";
import SurveyList from "./pages/survey/SurveyList";
import QuestionListByBlock from "./pages/survey/QuestionListByBlock";
import AnswerListByQuestion from "./pages/survey/AnswerListByQuestion";
import BlockListBySurvey from "./pages/survey/BlockListBySurvey";
import QuestionDetail from "./pages/survey/QuestionDetail";
import SelectSurveyToFillOut from "./pages/fillOutSurvey/SelectSurveyToFillOut";
import SelectSurveyToReport from "./pages/report/SelectSurveyToReport";
import ChartExample from "./pages/charts/ChartExample";

function App() {
    // @ts-ignore
    const auth = useSelector(state => state.auth);
    console.log(auth)

    return (
            <PersistGate persistor={persistor} loading={null}>
                <Router>
                    <Layout style={{ minHeight: '100vh' }}>
                        <AdminHeader/>
                        <Content style={{ margin: 12 }}>
                            <Routes>
                                <Route path="/surveys" element={<RequireAuth><SurveyList/></RequireAuth>} />
                                <Route path="/blocks/:surveyid" element={<RequireAuth><BlockListBySurvey/></RequireAuth>} />
                                <Route path="/questions/:blockid" element={<RequireAuth><QuestionListByBlock/></RequireAuth>} />
                                <Route path="/questionDetail/:persistibleitemid" element={<RequireAuth><QuestionDetail/></RequireAuth>} />
                                <Route path="/answers/:questionid" element={<RequireAuth><AnswerListByQuestion/></RequireAuth>} />
                                <Route path="/home" element={auth.logged ? <Home/> : <HomeWithoutAuth/>} />
                                <Route path="/login" element={<LoginRegisterUser/>} />
                                <Route path="/fillOutSurvey" element={<SelectSurveyToFillOut/>} />
                                <Route path="/getReport" element={<RequireAuth><SelectSurveyToReport/></RequireAuth>} />
                                {/*<Route path="/charts" element={<ChartExample/>} />*/}
                                <Route path="/" element={auth.logged ? <Home/> : <HomeWithoutAuth/>} />
                                {/*<Route path="/" element={<LoginRegisterUser/>} />*/}
                            </Routes>
                        </Content>
                    </Layout>
                </Router>
            </PersistGate>
    );
}

export default App;
