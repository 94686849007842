import React, {useEffect, useState} from 'react';
import {
    Button,
    Form,
    Input,
    Modal,
    Space,
    message, Popconfirm, Select, Row, Col
} from "antd";
import PhocusTable from "../../components/PhocusTable/PhocusTable";
import {useDispatch, useSelector} from "react-redux";
import {IQuestionGroup} from "../../interfaces/IQuestionGroup";
import {ISurveyItem} from "../../interfaces/ISurveyItem";
import RestClient from "../../Controller/rest/RestClient";
import {rootServiceURL} from "../../Controller";
import {ISurveyQuestion} from "../../interfaces/ISurveyQuestion";
import {IField} from "../../interfaces/IField";
import {deleteSurveyQuestion} from "./actions";
import {useNavigate} from "react-router-dom";
import './survey.css'

// Define la unión de tipos para la prop question
type QuestionProp = IQuestionGroup | ISurveyItem;

interface Props {
    question: QuestionProp;
    loadQuestion: () => void; // Define el tipo de la función loadQuestion
}

export default function QuestionGroupDetail({ question, loadQuestion }: Props){

    const [form] = Form.useForm();
    const [questionsForm] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const questionid = question.persistibleitemid;
    // @ts-ignore
    const auth = useSelector(state => state.auth);
    const [list, setList] = useState<ISurveyQuestion[]>([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updateFieldValues, setUpdateFieldValues] = useState<IQuestionGroup | undefined>(undefined);
    const [questionsUpdateFieldValues, setQuestionsUpdateFieldValues] = useState<ISurveyQuestion | undefined>(undefined);

    useEffect(() => {
        updateQuestionGroupForm();
        loadQuestions();
    }, [])

    const updateQuestionGroupForm = () => {
        cleanFormData();

        //@ts-ignore
        const updatedFieldValues :IQuestionGroup= {};

        Object.keys(question)?.forEach(field => {

            //@ts-ignore
            updatedFieldValues[field] = question[field];

            //@ts-ignore
            form.setFieldValue(field, question[field]);
        });
        setUpdateFieldValues(updatedFieldValues);
    }

    const loadQuestions = () => {
        const token = auth.token;

        const restClient = new RestClient(dispatch)
            .token(token).url(rootServiceURL() +"phocusservice/questionsByQuestionGroup/" + questionid);

        restClient.doGet((result:[ISurveyQuestion]) => {
            result && setList(result)
        })
    }

    const columns : IField<ISurveyQuestion>[]=
        [
            {
                title: 'Código',
                dataIndex: 'persistibleitemid',
                key: 'persistibleitemid',
                show:false,
                update:false,
                sorter: (a, b) => a.persistibleitemid > b.persistibleitemid ? 1 : -1,
            },
            {
                title: 'Orden',
                dataIndex: 'surveyitemorder',
                key: 'surveyitemorder',
                show:true,
                update:true,
                rules: [{ required: true, message: 'Please input order' }],
                sorter: (a, b) => a.surveyitemorder > b.surveyitemorder ? 1 : -1,
            },
            {
                title: 'Pregunta',
                dataIndex: 'caption',
                key: 'caption',
                show:true,
                update:true,
                rules: [{ required: true, message: 'Please input question' }],
                sorter: (a, b) => a.caption > b.caption ? 1 : -1,
            },
            {
                title: 'Actions',
                key: 'actions',
                render: (value, record) => {
                    return (
                        <Space size="middle" className="actions-column">
                            <a onClick={() => openUpdateModal(record)
                            }>Editar </a>
                            <a onClick={() => navigate("/questionDetail/"+record.persistibleitemid)
                            }>Detalle </a>
                            <Popconfirm
                                placement="left"
                                title="Eliminar Pregunta"
                                description={<>
                                    <p>
                                        "¿Está seguro de que quiere eliminar esta Pregunta?"
                                    </p>

                                </>}
                                onConfirm={() => {
                                        deleteSurveyQuestion(record.persistibleitemid, auth, dispatch, () => {loadQuestions()})
                                }}
                                onCancel={() => {}}
                                okText="Si"
                                cancelText="No"
                            >
                                <Button>Eliminar</Button>
                            </Popconfirm>
                        </Space>
                    );
                },
            }
        ];

    const openUpdateModal = (entity: ISurveyQuestion) => {
        cleanQuestionsFormData();

        //@ts-ignore
        const updatedFieldValues :ISurveyQuestion= {};

        Object.keys(entity)?.forEach(field => {

                //@ts-ignore
                updatedFieldValues[field] = entity[field];

                //@ts-ignore
                questionsForm.setFieldValue(field, entity[field]);
        });
        setQuestionsUpdateFieldValues(updatedFieldValues);
        setIsModalOpen(true)
    }

    const cleanQuestionsFormData = () => {
        questionsForm.resetFields();
        setQuestionsUpdateFieldValues(undefined);
    }

    const cleanFormData = () => {
        form.resetFields();
        setUpdateFieldValues(undefined);
    }

    const onFinish = (values : IQuestionGroup) => {
        let entityToPost = {
            ...updateFieldValues,
            ...values,
            blockid: {blockid: question.blockid.blockid}
        };

        const url = rootServiceURL() + 'phocusservice/questiongroup';

        const token = auth.token;

        const rc = new RestClient(dispatch).token(token).url(url);

        rc.doPost(entityToPost, (result: any) => {
            if (rc.isSuccess()) {
                message.success("Pregunta actualizada correctamente");
                loadQuestion();
            }
            else{
                message.error( "Error actualizando Pregunta "+rc.getErrors());
            }

        }, false);
    }

    const onFinishQuestions = (values : ISurveyQuestion) => {
        let entityToPost = {
            ...questionsUpdateFieldValues,
            ...values,
            blockid: null,
            surveyitemtype: "surveyquestion",
            type: "SQ",
            questiongroup: {persistibleitemid: question.persistibleitemid}
        };

        const urltype = "surveyquestion"
        const url = rootServiceURL() + 'phocusservice/' + urltype;

        const token = auth.token;

        const rc = new RestClient(dispatch).token(token).url(url);

        const update = questionsUpdateFieldValues && questionsUpdateFieldValues!.persistibleitemid !== undefined && questionsUpdateFieldValues!.persistibleitemid > 0;
        if(!update){
            console.log("Creando Pregunta: " ,entityToPost)

            rc.doPost(entityToPost, (result: any) => {
                if (rc.isSuccess()) {
                    message.success("Pregunta creada correctamente")
                    loadQuestions();
                    setIsModalOpen(false);
                    cleanQuestionsFormData();
                }
                else{
                    message.error( "Error creando Pregunta "+rc.getErrors());
                }

            }, false);

        } else {
            rc.doPost(entityToPost, (result: any) => {
                if (rc.isSuccess()) {
                    message.success("Pregunta actualizada correctamente");
                    loadQuestions();
                    setIsModalOpen(false);
                    cleanQuestionsFormData();
                }
                else{
                    message.error( "Error actualizando Pregunta "+rc.getErrors());
                }

            }, false);
        }
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    return (
        <>
            <Form
                name="basic"
                form={form}
                initialValues={{ remember: false }}
                onFinish={(values) => onFinish(values)}
                scrollToFirstError
                autoComplete="off"
                style={{margin: '20px'}}
            >
                <Form.Item
                    labelCol={{ span: 24 }}
                    label={"Título"}
                    name={"caption"}
                    rules={[{ required: true, message: 'Please input answer' }]}
                >
                    <Input.TextArea
                        autoSize={{ minRows: 1, maxRows: 5 }} // Ajusta las filas mínimas y máximas
                        style={{ width: '100%', resize: 'none' }} // Evita que se redimensione horizontalmente
                    />
                </Form.Item>

                <Button type="primary" htmlType="submit">
                    Actualizar
                </Button>
            </Form>
            <Modal
                title={"Modificar Pregunta"}
                open={isModalOpen}
                footer={null}
                onCancel={() => {
                    cleanFormData();
                    setIsModalOpen(false); }}>
                <Form
                    name="basic"
                    form={questionsForm}
                    initialValues={{ remember: false }}
                    onFinish={(values) => onFinishQuestions(values)}
                    scrollToFirstError
                    autoComplete="off">
                    {columns.filter(field =>(field.update)).map(field => (
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label={""+field.title}
                            name={field.key as string}
                            rules={field.rules}
                            valuePropName={field.valuePropName}
                        >
                            {field.renderInput ? field.renderInput(field.key as string) : <Input/>}
                        </Form.Item>
                    ))}
                    <Button type="primary" htmlType="submit">
                        Crear / Actualizar
                    </Button>
                </Form>
            </Modal>
            <Button type="primary" onClick={showModal} style={{margin: '20px'}}>
                Crear nueva Pregunta
            </Button>
            <PhocusTable columns={columns} dataSource={list} />
        </>
    );
}