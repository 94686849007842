import React, {useEffect, useState} from 'react';
import {
    Button,
    Form,
    Input,
    Modal,
    Space,
    message, Popconfirm, Select, Row, Col
} from "antd";
import PhocusTable from "../../components/PhocusTable/PhocusTable";
import RestClient from "../../Controller/rest/RestClient";
import {rootServiceURL} from "../../Controller";
import {IField} from "../../interfaces/IField";
import {useDispatch, useSelector} from "react-redux";
import {ISurveyQuestion} from "../../interfaces/ISurveyQuestion";
import {IAnswer} from "../../interfaces/IAnswer";
import {ISurveyItem} from "../../interfaces/ISurveyItem";
import {INumericRangeAnswer} from "../../interfaces/INumericRangeAnswer";

type QuestionProp = ISurveyQuestion | ISurveyItem;

interface Props {
    question: QuestionProp;
    loadQuestion: () => void; // Define el tipo de la función loadQuestion
}

export default function SelectRange({ question, loadQuestion }: Props){

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const questionid = question?.persistibleitemid;
    // @ts-ignore
    const auth = useSelector(state => state.auth);
    const [numericRangeAnswer, setNumericRangeAnswer] = useState<INumericRangeAnswer | null>(null)
    const [list, setList] = useState<IAnswer[]>([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updateFieldValues, setUpdateFieldValues] = useState<INumericRangeAnswer | undefined>(undefined);

    useEffect(() => {
        loadNumericRangeAnswer();

        return () => {
        };
    }, []);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const loadNumericRangeAnswer = () => {
        const token = auth.token;

        const restClient = new RestClient(dispatch)
            .token(token).url(rootServiceURL() +"phocusservice/numericRangeAnswerByQuestion/" + questionid);

        restClient.doGet((result: INumericRangeAnswer) => {
            if (result) {
                setNumericRangeAnswer(result)
                if (result != null) {
                    const restAnswersClient = new RestClient(dispatch)
                        .token(token).url(rootServiceURL() +"phocusservice/answersByQuestion/" + questionid);
                    restAnswersClient.doGet((result: IAnswer[]) => {
                        console.log(result)
                        setList(result);
                    })
                }
            }
        });
    }

    const NumericRangeAnswerColumns : IField<INumericRangeAnswer>[] = [
        {
            title: 'Código',
            dataIndex: 'possibleanswersid',
            key: 'possibleanswersid',
            show:false,
            update:false,
            sorter: (a, b) => a.possibleanswersid > b.possibleanswersid ? 1 : -1,
        },
        {
            title: 'Mínimo',
            dataIndex: 'min',
            key: 'min',
            show:true,
            update:true,
            rules: [{ required: true, message: 'Please input min' }],
            sorter: (a, b) => a.min > b.min ? 1 : -1,
        },
        {
            title: 'Máximo',
            dataIndex: 'max',
            key: 'max',
            show:true,
            update:true,
            rules: [{ required: true, message: 'Please input max' }],
            sorter: (a, b) => a.max > b.max ? 1 : -1,
        }
    ]

    const columns : IField<IAnswer>[]=
        [
            {
                title: 'Código',
                dataIndex: 'answerid',
                key: 'answerid',
                show:false,
                update:false,
                sorter: (a, b) => a.answerid > b.answerid ? 1 : -1,
            },
            {
                title: 'Respuesta',
                dataIndex: 'caption',
                key: 'caption',
                show:true,
                update:true,
                rules: [{ required: true, message: 'Please input answer' }],
                sorter: (a, b) => a.caption > b.caption ? 1 : -1,
            }
        ];

    const openUpdateModal = () => {
        cleanFormData();

        if (numericRangeAnswer != null) {
            //@ts-ignore
            const updatedFieldValues :INumericRangeAnswer= {};
            Object.keys(numericRangeAnswer)?.forEach(field => {

                //@ts-ignore
                updatedFieldValues[field] = numericRangeAnswer[field];

                //@ts-ignore
                form.setFieldValue(field, numericRangeAnswer[field]);
            });
            setUpdateFieldValues(updatedFieldValues);
        }

        setIsModalOpen(true)
    }

    const cleanFormData = () => {
        form.resetFields();
        setUpdateFieldValues(undefined);
    }

    const onFinish = (values : INumericRangeAnswer) => {
        let entityToPost = {
            ...updateFieldValues,
            ...values,
            surveyquestionid: {persistibleitemid: questionid}
        };

        const url = rootServiceURL() + "phocusservice/numericRangeAnswer";

        const token = auth.token;

        const rc = new RestClient(dispatch).token(token).url(url);

        const update = updateFieldValues && updateFieldValues!.possibleanswersid !== undefined && updateFieldValues!.possibleanswersid > 0;
        if(!update){
            console.log("Creando Rango: " ,entityToPost)

            rc.doPost(entityToPost, (result: any) => {
                if (rc.isSuccess()) {
                    message.success("Rango creada correctamente")
                    loadQuestion();
                    loadNumericRangeAnswer()
                    setIsModalOpen(false);
                    cleanFormData();
                }
                else{
                    message.error( "Error creando Rango "+rc.getErrors());
                }

            }, false);

        } else {
            rc.doPost(entityToPost, (result: any) => {
                if (rc.isSuccess()) {
                    message.success("Rango actualizada correctamente");
                    loadQuestion();
                    loadNumericRangeAnswer()
                    setIsModalOpen(false);
                    cleanFormData();
                }
                else{
                    message.error( "Error actualizando Rango "+rc.getErrors());
                }

            }, false);
        }
    }

    return (
        <>
            <Modal
                title={"Rango"}
                open={isModalOpen}
                footer={null}
                onCancel={() => {
                    cleanFormData();
                    setIsModalOpen(false); }}>
                <Form
                    name="basic"
                    form={form}
                    initialValues={{ remember: false }}
                    onFinish={(values) => onFinish(values)}
                    scrollToFirstError
                    autoComplete="off">
                    {NumericRangeAnswerColumns.filter(field =>(field.update)).map(field => (
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label={""+field.title}
                            name={field.key as string}
                            rules={field.rules}
                            valuePropName={field.valuePropName}
                        >
                            {field.renderInput ? field.renderInput(field.key as string) : <Input/>}
                        </Form.Item>
                    ))}
                    <Button type="primary" htmlType="submit">
                        Actualizar
                    </Button>
                </Form>
            </Modal>
            <Button type="primary" onClick={openUpdateModal} style={{margin: '20px'}}>
                Crear / Modificar rango
            </Button>
            {list.length ? <PhocusTable columns={columns} dataSource={list} /> : ""}
        </>
    );
}