import React, {useEffect, useState} from 'react';
import {
    Button,
    Form,
    Input,
    Modal,
    Space,
    message, Popconfirm, Select, Row, Col
} from "antd";
import PhocusTable from "../../components/PhocusTable/PhocusTable";
import RestClient from "../../Controller/rest/RestClient";
import {rootServiceURL} from "../../Controller";
import {IField} from "../../interfaces/IField";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {deleteBlock} from "./actions";
import {ISurvey} from "../../interfaces/ISurvey";
import {IBlock} from "../../interfaces/IBlock";
import './survey.css'

export default function BlockListBySurvey(){

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { surveyid } = useParams();
    // @ts-ignore
    const auth = useSelector(state => state.auth);
    const [survey, setSurvey] = useState<ISurvey | undefined>(undefined);
    const [list, setList] = useState<IBlock[]>([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updateFieldValues, setUpdateFieldValues] = useState<IBlock | undefined>(undefined);

    useEffect(() => {
        loadSurvey();

        return () => {
        };
    }, []);

    const showModal = () => {
        setIsModalOpen(true);
    };

    function loadSurvey () {
        const token = auth.token;

        const restClient = new RestClient(dispatch)
            .token(token).url(rootServiceURL() +"phocusservice/survey/" + surveyid);

        restClient.doGet((result: ISurvey) => {
            if (result) {
                setSurvey(result)
                const restTimeSlotClient = new RestClient(dispatch)
                    .token(token).url(rootServiceURL() +"phocusservice/blocksBySurveyid/" + surveyid);
                restTimeSlotClient.doGet((result: IBlock[]) => {
                    setList(result);
                })
            }
        });
    }

    const columns : IField<IBlock>[]=
        [
            {
                title: 'Código',
                dataIndex: 'blockid',
                key: 'blockid',
                show:false,
                update:false,
                sorter: (a, b) => a.blockid > b.blockid ? 1 : -1,
            },
            {
                title: 'Orden',
                dataIndex: 'blockorder',
                key: 'blockorder',
                show:true,
                update:true,
                rules: [{ required: true, message: 'Please input an order' }],
                sorter: (a, b) => a.blockorder > b.blockorder ? 1 : -1,
            },
            {
                title: 'Bloque',
                dataIndex: 'block',
                key: 'block',
                show:true,
                update:true,
                rules: [{ required: true, message: 'Please input block' }],
                sorter: (a, b) => a.block > b.block ? 1 : -1,
            },
            {
                title: 'Actions',
                key: 'actions',
                render: (value, record) => {
                    return (
                        <Space size="middle" className="actions-column">
                            <a onClick={() => openUpdateModal(record)}>Editar</a>
                            <a onClick={() => navigate("/questions/" + record.blockid)}>Preguntas</a>
                            <Popconfirm
                                placement="left"
                                title="Eliminar Bloque"
                                description={<p>¿Está seguro de que quiere eliminar este Bloque?</p>}
                                onConfirm={() => deleteBlock(record.blockid, auth, dispatch, () => loadSurvey())}
                                okText="Si"
                                cancelText="No"
                            >
                                <Button>Eliminar</Button>
                            </Popconfirm>
                        </Space>
                    );
                },
            },

        ];

    const openUpdateModal = (entity: IBlock) => {
        cleanFormData();

        //@ts-ignore
        const updatedFieldValues :IBlock= {};

        Object.keys(entity)?.forEach(field => {

                //@ts-ignore
                updatedFieldValues[field] = entity[field];

                //@ts-ignore
                form.setFieldValue(field, entity[field]);
        });
        setUpdateFieldValues(updatedFieldValues);
        setIsModalOpen(true)
    }

    const cleanFormData = () => {
        form.resetFields();
        setUpdateFieldValues(undefined);
    }

    const onFinish = (values : IBlock) => {
        let entityToPost = {
            ...updateFieldValues,
            ...values,
            surveyid: {surveyid: surveyid}
        };

        const url = rootServiceURL() + 'phocusservice/block';

        const token = auth.token;

        const rc = new RestClient(dispatch).token(token).url(url);

        const update = updateFieldValues && updateFieldValues!.blockid !== undefined && updateFieldValues!.blockid > 0;
        if(!update){
            console.log("Creando Bloque: " ,entityToPost)

            rc.doPost(entityToPost, (result: any) => {
                if (rc.isSuccess()) {
                    message.success("Bloque creado correctamente")
                    loadSurvey();
                    setIsModalOpen(false);
                    cleanFormData();
                }
                else{
                    message.error( "Error creando Bloque "+rc.getErrors());
                }

            }, false);

        } else {
            console.log("Modificando Bloque: " ,entityToPost)
            // @ts-ignore
            delete entityToPost.questions
            rc.doPost(entityToPost, (result: any) => {
                if (rc.isSuccess()) {
                    message.success("Bloque actualizado correctamente");
                    loadSurvey();
                    setIsModalOpen(false);
                    cleanFormData();
                }
                else{
                    message.error( "Error actualizando Bloque "+rc.getErrors());
                }

            }, false);
        }
    }

    return (
        <>
            <div style={{background: "whitesmoke"}}>
                <Row justify={"center"}>
                    <Col span={24} style={{display:"flex", alignItems: "center", color: "#4D590D", fontSize: 40, flexDirection: "column"}}>
                        <p>Encuesta: {survey?.surveyTitle}</p>
                    </Col>
                </Row>
            </div>
            <Modal
                title={"Modificar Bloque"}
                open={isModalOpen}
                footer={null}
                onCancel={() => {
                    cleanFormData();
                    setIsModalOpen(false); }}>
                <Form
                    name="basic"
                    form={form}
                    initialValues={{ remember: false }}
                    onFinish={(values) => onFinish(values)}
                    scrollToFirstError
                    autoComplete="off">
                    {columns.filter(field =>(field.update)).map(field => (
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label={""+field.title}
                            name={field.key as string}
                            rules={field.rules}
                            valuePropName={field.valuePropName}
                        >
                            {field.renderInput ? field.renderInput(field.key as string) : <Input/>}
                        </Form.Item>
                    ))}
                    <Button type="primary" htmlType="submit">
                        Actualizar
                    </Button>
                </Form>
            </Modal>
            <Button type="primary" onClick={showModal} style={{margin: '20px'}}>
                Crear nuevo Bloque
            </Button>
            <PhocusTable columns={columns} dataSource={list}/>
        </>
    );
}