import {
    Button,
    Checkbox,
    Col,
    Flex,
    Form,
    Input,
    InputNumber, message,
    Radio,
    RadioChangeEvent,
    Row,
    Select, Space, Spin,
    Typography
} from "antd";
import {useDispatch, useSelector} from "react-redux";
import RestClient from "../../Controller/rest/RestClient";
import {ISurvey} from "../../interfaces/ISurvey";
import {IBlock} from "../../interfaces/IBlock";
import React, {ChangeEvent, useEffect, useState} from "react";
import {rootServiceURL} from "../../Controller";
import {ISurveyItem} from "../../interfaces/ISurveyItem";
import SelectRange from "../survey/SelectRange";
import {ISurveyQuestion} from "../../interfaces/ISurveyQuestion";
import {IListAnswer} from "../../interfaces/IListAnswer";
import {IQuestionGroup} from "../../interfaces/IQuestionGroup";

interface Props {
    surveyid : number;
}

interface CustomizeQuestions {
    [key: string]: string;
}

export default function SurveyToFillOut ({ surveyid }: Props) {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    // @ts-ignore
    const auth = useSelector(state => state.auth);

    const [survey, setSurvey] = useState<ISurvey | undefined>(undefined);
    const [customizeQuestions, setCustomizeQuestions] = useState<CustomizeQuestions>({})
    const [isLoading, setIsLoading] = useState(true)
    const [isFinish, setIsFinish] = useState(false)
    const [selectedValuesByQuestion, setSelectedValuesByQuestion] = useState<Record<number, any[]>>({});

    useEffect(() => {
        loadSurvey();

        return () => {
        };
    }, []);

    function loadSurvey () {

        const restClient = new RestClient(dispatch).url(rootServiceURL() +"phocusservice/surveyToFillOut/" + surveyid);
        restClient.doGet((result: ISurvey) => {
            console.log (result)
            setSurvey(result)
            setIsLoading(false)
        })
    }

    const onCheckboxChange = (checkedValues: any[], surveyQuestion:ISurveyQuestion) => {
        const questionId = surveyQuestion.persistibleitemid

        setSelectedValuesByQuestion(prev => ({
            ...prev,
            [questionId]: checkedValues  // Actualizamos solo la pregunta correspondiente
        }));

        // Si se alcanza el límite permitido, mostramos un mensaje para la pregunta correspondiente
        const question = surveyQuestion
        if (question?.numanswers && checkedValues.length === question.numanswers) {
            message.info(`Has seleccionado el número máximo de respuestas permitidas (${question.numanswers}) para la pregunta "${question.caption}".`);
        }
    };

    const onChangeCustomizeInputs = (e : ChangeEvent<HTMLInputElement>) => {
        const customizeInputChange = {...customizeQuestions}
        customizeInputChange[e.target.name] = e.target.value
        setCustomizeQuestions(customizeInputChange)
    }

    const onFinish = (values : any) => {
        const entityToPost = {
            surveyid: surveyid,
            questions: {
                ...values
            }
        }
        //Añadimos las respuestas customizadas
        Object.keys(customizeQuestions).forEach(key => {
            const customValue = customizeQuestions[key];
            const questionValue = entityToPost.questions[key];

            if (Array.isArray(questionValue)) {
                // Si es un array, eliminamos 'Otros' y añadimos el valor personalizado
                entityToPost.questions[key] = questionValue.filter((val: string) => val !== 'Otros').concat(customValue);
            } else {
                // Si no es un array, simplemente reemplazamos el valor
                entityToPost.questions[key] = customValue;
            }
        });

        //Transformamos todas las respuestas para que estén en una List
        Object.keys(entityToPost.questions).forEach(key => {
            if (!Array.isArray(entityToPost.questions[key])) {
                entityToPost.questions[key] = [entityToPost.questions[key]];
            }
        });

        console.log(entityToPost)
        const url = rootServiceURL() + 'phocusservice/completedsurvey';

        const token = auth.token;

        const rc = new RestClient(dispatch).token(token).url(url);

        rc.doPost(entityToPost, (result: any) => {
            if (rc.isSuccess()) {
                message.success("Formulario creado correctamente");
                setIsFinish(true)
            }
            else{
                message.error( "Error creando Formulario "+rc.getErrors());
            }

        }, false);
    }

    const drawSurveyCuestion = (surveyQuestion : ISurveyQuestion) => {
        console.log(surveyQuestion)
        if ('possibleAnswers' in surveyQuestion && surveyQuestion.possibleAnswers) {
            const possibleAnswers = surveyQuestion.possibleAnswers as IListAnswer;
            if (!surveyQuestion.restrictAnswerToPossibleOnly) {
                if(!possibleAnswers.values.some(v => v.answerid === 0)){
                    possibleAnswers.values.push({
                        answerid: 0,
                        numericvalue: 0,
                        possibleanswersid: possibleAnswers,
                        value: "",
                        caption: 'OtrosCustom',
                        answerorder: possibleAnswers.values.length + 1 });
                }
            }
            possibleAnswers.values.sort((a,b) => a.answerorder - b.answerorder)
            return (
                <>
                {surveyQuestion.questionType == "QUESTION_TYPE_SELECT" || surveyQuestion.questionType == "QUESTION_TYPE_SELECT_RANGE" ?
                    <Form.Item
                        key={surveyQuestion.persistibleitemid}
                        labelCol={{ span: 24 }}
                        label={surveyQuestion.caption}
                        name={surveyQuestion.persistibleitemid}
                        rules={[{ required: surveyQuestion.mandatory, message: 'Please input ' +  surveyQuestion.caption},
                            () => ({
                                validator(_, value) {
                                    // Validar si existe la propiedad numanswersexact
                                    if (surveyQuestion.numanswersexact !== undefined) {
                                        if ((value?.length || 0) !== surveyQuestion.numanswers) {
                                            return Promise.reject(
                                                `Debe seleccionar exactamente ${surveyQuestion.numanswers} respuestas para ${surveyQuestion.caption}`
                                            );
                                        }
                                    }
                                    // Validación pasa sin errores
                                    return Promise.resolve();
                                },
                            })
                        ]}
                    >
                        {surveyQuestion.multiple ? (
                            <Checkbox.Group
                                value={selectedValuesByQuestion[surveyQuestion.persistibleitemid] || []}
                                onChange={(checkedValues) => {onCheckboxChange(checkedValues, surveyQuestion)}}  // Gestionamos los cambios en el checkbox
                                style={{ display: 'block' }}
                            >
                                {possibleAnswers.values.map(answer => (
                                    <Checkbox
                                        key={surveyQuestion.persistibleitemid + "-" + answer.caption}
                                        value={answer.caption}
                                        style={{ display: 'flex' }}
                                        disabled={
                                            !!(surveyQuestion.numanswers &&
                                                (selectedValuesByQuestion[surveyQuestion.persistibleitemid]?.length || 0) >= surveyQuestion.numanswers &&
                                                !selectedValuesByQuestion[surveyQuestion.persistibleitemid]?.includes(answer.caption))  // Controlamos deshabilitar solo para la pregunta actual
                                        }
                                    >
                                        {answer.caption == 'OtrosCustom' ? <Input name={surveyQuestion.persistibleitemid.toString()} onChange={onChangeCustomizeInputs}/> : answer.caption}
                                    </Checkbox>
                                ))}
                            </Checkbox.Group>
                        ) : (
                            <Radio.Group style={{ display: 'block' }}>
                                {possibleAnswers.values.map(answer => (
                                    <Radio
                                        key={surveyQuestion.persistibleitemid + "-" + answer.caption}
                                        value={answer.caption}
                                        style={{ display: 'flex' }}
                                    >
                                        {answer.caption == 'OtrosCustom' ? <Input name={surveyQuestion.persistibleitemid.toString()} onChange={onChangeCustomizeInputs}/> : answer.caption}
                                    </Radio>
                                ))}
                            </Radio.Group>
                        )}
                    </Form.Item>
                :
                    <>
                        {surveyQuestion.questionType === 'QUESTION_TYPE_TEXT' && surveyQuestion.multiple ?
                            <>
                            <Form.Item
                                key={surveyQuestion.persistibleitemid}
                                labelCol={{ span: 24 }}
                                label={surveyQuestion.caption}
                                rules={[{ required: surveyQuestion.mandatory, message: 'Please input ' +  surveyQuestion.caption}]}
                            />
                            <Form.List name={surveyQuestion.persistibleitemid}>
                                {(fields, { add, remove }) => {
                                    const initialFields = surveyQuestion.numanswers
                                        ? Array.from({ length: surveyQuestion.numanswers }, (_, i) => ({
                                            key: `fixed-field-${i}`, // Asignar claves únicas
                                            name: i,
                                        }))
                                        : fields
                                    return (
                                    <>
                                        {initialFields.map((field, index) => (
                                            <Form.Item
                                                key={field.key}
                                                label={`${index + 1}`}
                                                required={surveyQuestion.mandatory}
                                                style={{ marginBottom: '10px' }}
                                            >
                                                <Space align="baseline">
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name]}
                                                        fieldKey={[field.key || `fieldKey-${index}`]}
                                                        rules={[{ required: surveyQuestion.mandatory, message: 'La pregunta ' +  surveyQuestion.caption + ' es obligatoria'}]}
                                                        noStyle
                                                    >
                                                        <Input placeholder="Introduce tu respuesta" />
                                                    </Form.Item>
                                                    {!surveyQuestion.numanswers && (
                                                        <Button
                                                            type="link"
                                                            onClick={() => remove(field.name)}
                                                            style={{ marginLeft: '10px', marginTop: '10px' }}
                                                        >
                                                            Eliminar
                                                        </Button>
                                                    )}
                                                </Space>
                                            </Form.Item>
                                        ))}

                                        {!surveyQuestion.numanswers && (
                                            <Button type="dashed" onClick={() => add()} style={{ marginBottom: '20px' }}>
                                                + Añadir Respuesta
                                            </Button>
                                        )}
                                    </>
                                )}}
                            </Form.List>
                            </>
                            :
                            <Form.Item
                                key={surveyQuestion.persistibleitemid}
                                labelCol={{ span: 24 }}
                                label={surveyQuestion.caption}
                                name={surveyQuestion.persistibleitemid}
                                rules={[{ required: surveyQuestion.mandatory, message: 'Please input ' +  surveyQuestion.caption}]}
                            >
                                {surveyQuestion.questionType == "QUESTION_TYPE_INTEGER" || surveyQuestion.questionType == "QUESTION_TYPE_DOUBLE" ?
                                    <>
                                        {surveyQuestion.questionType == "QUESTION_TYPE_INTEGER" ?
                                            <InputNumber min={1}/>
                                            :
                                            <InputNumber min={1} precision={2}/>
                                        }
                                    </>
                                    :
                                    <Input/>
                                }
                            </Form.Item>}
                    </>
                }
                </>
            )
        }
    }

    return (
        <>
            {isLoading ? <Spin spinning={isLoading} fullscreen /> :
                isFinish ?  <Typography.Title level={2}>Cuestionario Enviado</Typography.Title> :
                    <>
                        <div style={{background: "whitesmoke"}}>
                            <Row justify={"center"}>
                                <Col span={24} style={{display:"flex", alignItems: "center", color: "#4D590D", fontSize: 40, flexDirection: "column"}}>
                                    <p>Encuesta: {survey?.surveyTitle}</p>
                                </Col>
                            </Row>
                        </div>
                        <Form
                            name="basic"
                            form={form}
                            initialValues={{ remember: false }}
                            onFinish={(values) => onFinish(values)}
                            scrollToFirstError
                            autoComplete="off"
                            style={{margin: '20px'}}
                        >
                            {survey?.blocks?.length ? survey.blocks.length> 0 ? survey.blocks.sort((a, b) => a.blockorder - b.blockorder)
                                    && survey.blocks.map((block, i) => (
                                        <div key={i}>
                                            <Typography.Title level={4}>{block.block}</Typography.Title>
                                            {block.questions.length > 0 ?
                                                block.questions.sort((a, b) => a.surveyitemorder - b.surveyitemorder) &&
                                                block.questions.map(question => {
                                                        if (question.surveyitemtype == "QG") {
                                                            const questionGroup = question as IQuestionGroup;
                                                            if ('questions' in questionGroup && questionGroup.questions.length > 0){
                                                                questionGroup.questions.sort((a,b) => a.surveyitemorder - b.surveyitemorder)
                                                                return (
                                                                    <div key={questionGroup.persistibleitemid}>
                                                                        <Typography.Title level={5}>{questionGroup.caption}</Typography.Title>
                                                                        {questionGroup.questions.map(surveyQuestion => {
                                                                            return drawSurveyCuestion(surveyQuestion)
                                                                        })}
                                                                    </div>
                                                                )
                                                            }
                                                        } else if (question.surveyitemtype == "SQ") {
                                                            const surveyQuestion = question as ISurveyQuestion;
                                                            return drawSurveyCuestion(surveyQuestion)
                                                        }
                                                    }
                                                )
                                                :
                                                ""}
                                        </div>
                                    ))
                                    : ""
                                : ""}

                            <Button type="primary" htmlType="submit">
                                Enviar
                            </Button>
                        </Form>
                    </>
            }
        </>
    )
}