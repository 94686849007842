import {
    AUTH_LOGGED,
    AUTH_LOGIN_REQUESTED,
    AUTH_LOGIN_FAILED,
    AUTH_LOGIN_FAILED_ACK,
    AUTH_RESTORE_SESSION,
    AUTH_LOGOUT,
    AUTH_SESSION_EXPIRED,
    AUTH_DONT_ASK_FOR_LOGIN,
    AUTH_SWITCH_ROLES_TEMPORARY
} from './actions';

const initialState = {
    token: '',
    phocusUser: {},
    loginRequested: false,
    logged: false,
    loginFailed: false,
    loginFailedMessage: '',
    sessionExpired: true,
    askForLogin: false,
    temporaryRoles: null
}

export const auth = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_LOGIN_REQUESTED:
            return {
                ...state,
                loginRequested: true
            };
        case AUTH_LOGOUT:
            return {
                ...initialState
            };
        case AUTH_LOGGED:
            return {
                ...state,
                phocusUser: action.authData.phocusUser,
                token: action.authData.token,
                loginRequested: false,
                logged: true,
                sessionExpired: false,
                askForLogin: false,
                loginFailed: false
            };
        case AUTH_LOGIN_FAILED:
            return {
                ...state,
                loginRequested: false,
                loginFailed: true,
                logged: false,
                loginFailedMessage: action.message
            }
        case AUTH_LOGIN_FAILED_ACK:
            return {
                ...state,
                loginFailed: false,
                loginFailedMessage: ''
            }
        case AUTH_RESTORE_SESSION:
            return {
                ...state,
                logged: true,
                sessionExpired: false,
                token: action.token,
                phocusUser: action.phocusUser
            }
        case AUTH_SESSION_EXPIRED:
            return {
                ...state,
                token: '',
                logged: false,
                sessionExpired: true,
                askForLogin: true
            }
        case AUTH_DONT_ASK_FOR_LOGIN:
            return {
                ...state,
                askForLogin: false
            }
        case AUTH_SWITCH_ROLES_TEMPORARY:
            return {
                ...state,
                temporaryRoles: action.newRoles
            }
        default:
            return state;
    }
};