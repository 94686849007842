import {ADD_NOTIFICATION, ACK_NOTIFICATION} from "./actions"

const initialState = {
    notifications: [],
    toasts: []
}

export const notification = (state = initialState, action) => {
    switch (action.type) {
        case ADD_NOTIFICATION:
            return {
                ...state,
                notifications: [...state.notifications, action.notification]
            }

        case ACK_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter(notification => notification.id != action.notification.id)
            }

        default:
            return state;
    }
}
