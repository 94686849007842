import React, {useEffect, useState} from 'react';
import {
    Button,
    Form,
    Input,
    Modal,
    Space,
    message, Popconfirm, Select, Row, Col
} from "antd";
import PhocusTable from "../../components/PhocusTable/PhocusTable";
import RestClient from "../../Controller/rest/RestClient";
import {rootServiceURL} from "../../Controller";
import {IField} from "../../interfaces/IField";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {deleteAnswer} from "./actions";
import {ISurveyQuestion} from "../../interfaces/ISurveyQuestion";
import {IAnswer} from "../../interfaces/IAnswer";
import {testAnswer1, testQuestion1} from "./surveyTest";


export default function AnswerListByQuestion(){

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const { questionid } = useParams();
    // @ts-ignore
    const auth = useSelector(state => state.auth);
    const [question, setQuestion] = useState<ISurveyQuestion | undefined>(undefined);
    const [list, setList] = useState<IAnswer[]>([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updateFieldValues, setUpdateFieldValues] = useState<IAnswer | undefined>(undefined);

    useEffect(() => {
        loadQuestion();

        return () => {
        };
    }, []);

    const showModal = () => {
        setIsModalOpen(true);
    };

    function loadQuestion () {
        /*const token = auth.token;

        const restClient = new RestClient(dispatch)
            .token(token).url(rootServiceURL() +"fullgraph/rest/question/" + questionid);

        restClient.doGet((result: ISurveyQuestion) => {
            if (result) {
                setQuestion(result)
                const restTimeSlotClient = new RestClient(dispatch)
                    .token(token).url(rootServiceURL() +"fullgraph/rest/question/" + questionid +"/answer");
                restTimeSlotClient.doGet((result: IAnswer[]) => {
                    setList(result);
                })
            }
        });*/
        // @ts-ignore
        setQuestion(testQuestion1)
        // @ts-ignore
        setList([testAnswer1])
    }

    const columns : IField<IAnswer>[]=
        [
            {
                title: 'Código',
                dataIndex: 'answerid',
                key: 'answerid',
                show:false,
                update:false,
                sorter: (a, b) => a.answerid > b.answerid ? 1 : -1,
            },
            {
                title: 'Respuesta',
                dataIndex: 'answer',
                key: 'answer',
                show:true,
                update:true,
                rules: [{ required: true, message: 'Please input answer' }],
                sorter: (a, b) => a.value > b.value ? 1 : -1,
            },
            {
                title: 'Actions',
                key: 'actions',
                render: (value, record) => {
                    return (
                        <Space size="middle">
                            <a onClick={() => openUpdateModal(record)
                            }>Editar </a>
                            <Popconfirm
                                placement="left"
                                title="Eliminar Respuesta"
                                description={<>
                                    <p>
                                        "¿Está seguro de que quiere eliminar esta Respuesta?"
                                    </p>

                                </>}
                                onConfirm={() => {
                                    deleteAnswer(record.answerid, auth, dispatch, () => {loadQuestion()})
                                }}
                                onCancel={() => {}}
                                okText="Si"
                                cancelText="No"
                            >
                                <Button>Eliminar</Button>
                            </Popconfirm>
                        </Space>
                    );
                },
            }
        ];

    const openUpdateModal = (entity: IAnswer) => {
        cleanFormData();

        //@ts-ignore
        const updatedFieldValues :IAnswer= {};

        Object.keys(entity)?.forEach(field => {

                //@ts-ignore
                updatedFieldValues[field] = entity[field];

                //@ts-ignore
                form.setFieldValue(field, entity[field]);
        });
        setUpdateFieldValues(updatedFieldValues);
        setIsModalOpen(true)
    }

    const cleanFormData = () => {
        form.resetFields();
        setUpdateFieldValues(undefined);
    }

    const onFinish = (values : IAnswer) => {
        let entityToPost = {
            ...updateFieldValues,
            ...values,
            questionid: {questionid: questionid}
        };

        const url = rootServiceURL() + 'minimalgraph/rest/answer';

        const token = auth.token;

        const rc = new RestClient(dispatch).token(token).url(url);

        const update = updateFieldValues && updateFieldValues!.answerid !== undefined && updateFieldValues!.answerid > 0;
        if(!update){
            console.log("Creando Respuesta: " ,entityToPost)

            rc.doPost(entityToPost, (result: any) => {
                if (rc.isSuccess()) {
                    message.success("Respuesta creada correctamente")
                    loadQuestion();
                    setIsModalOpen(false);
                    cleanFormData();
                }
                else{
                    message.error( "Error creando Respuesta "+rc.getErrors());
                }

            }, false);

        } else {
            rc.doPut(entityToPost, (result: any) => {
                if (rc.isSuccess()) {
                    message.success("Respuesta actualizada correctamente");
                    loadQuestion();
                    setIsModalOpen(false);
                    cleanFormData();
                }
                else{
                    message.error( "Error actualizando Respuesta "+rc.getErrors());
                }

            }, false);
        }
    }

    return (
        <>
            <div style={{background: "whitesmoke"}}>
                <Row justify={"center"}>
                    <Col span={24} style={{display:"flex", alignItems: "center", color: "#4D590D", fontSize: 40, flexDirection: "column"}}>
                        <p>Pregunta: {question?.question}</p>
                    </Col>
                </Row>
            </div>
            <Modal
                title={"Modificar Respuesta"}
                open={isModalOpen}
                footer={null}
                onCancel={() => {
                    cleanFormData();
                    setIsModalOpen(false); }}>
                <Form
                    name="basic"
                    form={form}
                    initialValues={{ remember: false }}
                    onFinish={(values) => onFinish(values)}
                    scrollToFirstError
                    autoComplete="off">
                    {columns.filter(field =>(field.update)).map(field => (
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label={""+field.title}
                            name={field.key as string}
                            rules={field.rules}
                            valuePropName={field.valuePropName}
                        >
                            {field.renderInput ? field.renderInput(field.key as string) : <Input/>}
                        </Form.Item>
                    ))}
                    <Button type="primary" htmlType="submit">
                        Actualizar
                    </Button>
                </Form>
            </Modal>
            <Button type="primary" onClick={showModal} style={{margin: '20px'}}>
                Crear nueva Respuesta
            </Button>
            <PhocusTable columns={columns} dataSource={list} />
        </>
    );
}