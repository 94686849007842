import {
    CHANGE_TITLE_TO,
    PENDING_REQUEST,
    REQUEST_STARTED,
    REQUEST_FINISHED,
    CLEAR_PENDING_REQUEST,
    SET_SIDENAV_COLLAPSED,
    SET_HIDDEN_HEADER
} from './actions'

const initialState = {
    componentContent: {},
    title: 'PHOCUS',
    sideNavCollapsed: false,
    hiddenHeader: false,
    pendingRequests: [],
    requestsInProcess: 0
}

export const desktop = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_TITLE_TO:
            return {
                ...state,
                title: action.title
            }
        case REQUEST_STARTED:
            return {
                ...state,
                requestsInProcess: state.requestsInProcess + 1
            }
        case REQUEST_FINISHED:
            return {
                ...state,
                requestsInProcess: state.requestsInProcess - 1
            }
        case SET_SIDENAV_COLLAPSED:
            return {
                ...state,
                sideNavCollapsed: action.collapsed
            }
        case SET_HIDDEN_HEADER:
            return {
                ...state,
                hiddenHeader: action.hidden
            }
        case PENDING_REQUEST:
            return {
                ...state,
                pendingRequests: [...state.pendingRequests, {
                    url: action.url,
                    request: action.request,
                    callback: action.callback
                }]
            }
        case CLEAR_PENDING_REQUEST:
            return {
                ...state,
                pendingRequests: state.pendingRequests.filter((_, i) => i !== action.index)
            }
        default:
            return state;
    }
}