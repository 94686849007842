import React from "react";
import {Checkbox, DatePicker, InputNumber, Select, Space} from "antd";
import moment from "moment/moment";
import dayjs from "dayjs";


export function getCheckboxRender() {
    return (value, record) => {
        return (
            <Checkbox checked={value}/>
        );
    };
}

export function getCheckboxRenderInput() {
    return (value, record) => {
        return (
            <Checkbox/>
        );
    };
}

export function getDateRender() {
    return (record) => {
        return (
            <div>
                <p>{record ? moment(record).format("DD-MM-YYYY HH:mm") : ""}</p>
            </div>
        );
    };
}

export function getKwRender() {
    return (record) => {
        const spainRecord = record.toLocaleString('es-ES', { minimumFractionDigits: 4, maximumFractionDigits: 4 });
        return (
            <div>
                <p style={{ textAlign: 'right' }}>{spainRecord ? spainRecord + " KW" : ""}</p>
            </div>
        );
    }
}

export function getMoneyRender() {
    return (record) => {
        const spainRecord = record.toLocaleString('es-ES', { minimumFractionDigits: 4, maximumFractionDigits: 4 });
        return (
            <div>
                <p style={{ textAlign: 'right' }}>{spainRecord ? spainRecord + " €" : ""}</p>
            </div>
        );
    }
}

export function getDateRenderWithoutHour() {
    return (record) => {
        return (
            <div>
                <p>{record ? moment(record).format("DD-MM-YYYY") : ""}</p>
            </div>
        );
    };

}

export function getDateRenderInput(field, updateFieldValues, setUpdateFieldValues, form, showTime = true) {
    const value = updateFieldValues && updateFieldValues[field] ? dayjs(updateFieldValues[field]) : null;
    return (
        <Space direction="vertical" size={12}>
            <DatePicker showTime={showTime ? {format: 'HH:mm', minuteStep: 5} : false} defaultValue={value}
                        format="YYYY-MM-DD HH:mm"
                        showNow={false}
                        onChange={(date, dateString) => onChange(field, date, dateString, updateFieldValues, setUpdateFieldValues, form)}
                        onOk={(value) => onOk(field, value, updateFieldValues, setUpdateFieldValues, form)}/>
        </Space>
    );
}

export function getDateRenderInputWithoutHour(field, updateFieldValues, setUpdateFieldValues, form) {
    const value = updateFieldValues && updateFieldValues[field] ? dayjs(updateFieldValues[field]) : null;
    return (
        <Space direction="vertical" size={12}>
            <DatePicker showTime={false} defaultValue={value}
                        format= "DD-MM-YYYY"
                        showNow={false}
                        onChange={(date, dateString) => onChange(field, date, dateString, updateFieldValues, setUpdateFieldValues, form)}
                        onOk={(value) => onOk(field, value, updateFieldValues, setUpdateFieldValues, form)}/>
        </Space>
    );
}

export function getListRender (list) {
    return (
        <ul>
            {list.map (element => {
                return (
                <li>{element}</li>
                )
            })}
        </ul>
    )
}

export function getSurveyItemTypeRender () {
    return (record) => {
        return (
                <p>{record == "QG" || record == "questiongroup" ? "Pregunta de Grupo" : "Pregunta simple"}</p>
        );
    }
}

const onChange = (field, date, dateString, updateFieldValues, setUpdateFieldValues, form) => {
    const value = date ? date.startOf('minute').unix() * 1000 : null;
    form && form.setFieldsValue({ [field]: value  });
    setUpdateFieldValues && updateFieldValues && setUpdateFieldValues({...updateFieldValues, [field] : value})
}

const onOk = (field, date, updateFieldValues, setUpdateFieldValues, form) => {
    const value = date ? date.startOf('minute').unix() * 1000 : null;
    form && form.setFieldsValue({ [field]: value  });
    setUpdateFieldValues && updateFieldValues && setUpdateFieldValues({...updateFieldValues, [field] : value})
};