import {CONFIG}  from '../../index'
import RestClient from "../../rest/RestClient"

export const REGISTRATION_DIALOG_OPEN = 'REGISTRATION_DIALOG_OPEN'
export const REGISTRATION_DIALOG_CLOSE = 'REGISTRATION_DIALOG_CLOSE'
export const REGISTRATION_REQUESTED = 'REGISTRATION_REQUESTED'
export const REGISTRATION_FAILED = 'REGISTRATION_FAILED'
export const REGISTRATION_FAILED_ACK = 'REGISTRATION_FAILED_ACK'
export const REGISTRATION_OK = 'REGISTRATION_OK'

export const VERIFICATION_REQUESTED = 'VERIFICATION_REQUESTED'
export const VERIFICATION_OK = 'VERIFICATION_OK'
export const VERIFICATION_FAILED = 'VERIFICATION_FAILED'

export const register = (registrationData) => {
    return (dispatch) => {
        dispatch(registrationRequested());
        const restClient = new RestClient(dispatch).url(CONFIG.restService.restRegistrationUrl);
        restClient.doPost(registrationData, (data) => {
            if (data && data.success) {
                dispatch(registrationOk())
            } else {
                const message = data ? data.message : restClient.getErrors();
                dispatch(registrationFailed(message))
            }
        });
    }
}

export const registrationDialogOpen = () => {
    return {
        type: REGISTRATION_DIALOG_OPEN
    }
}

export const registrationDialogClose = () => {
    return {
        type: REGISTRATION_DIALOG_CLOSE
    }
}

export const registrationRequested = () => {
    return {
        type: REGISTRATION_REQUESTED
    }
}

export const registrationFailed = (message) => {
    return {
        type: REGISTRATION_FAILED,
        registrationError: message
    }
}

export const registrationFailedAck = () => {
    return {
        type: REGISTRATION_FAILED_ACK
    }
}

export const registrationOk = () => {
    return {
        type: REGISTRATION_OK
    }
}

export const verify = (verificationData) => {
    return (dispatch) => {
        dispatch(registrationRequested());
        const restClient = new RestClient(dispatch).url("http://localhost:8080/phocus-kpi/completegraph/phocusservice/verify");
        restClient.doPost(verificationData, (verificationResponse) => {
            if (verificationResponse && verificationResponse.success) {
                dispatch(verificationOk())
            } else {
                const message = verificationResponse ? verificationResponse.message : restClient.getErrors();
                dispatch(verificationFailed(message))
            }
        });
    }
}

export const verificationRequested = () => {
    return {
        type: VERIFICATION_REQUESTED
    }
}

export const verificationFailed = () => {
    return {
        type: VERIFICATION_FAILED
    }
}

export const verificationOk = () => {
    return {
        type: VERIFICATION_OK
    }
}
