import { Button, Card, Col, Row } from "antd";
import React from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

export default function Home() {
    const navigate = useNavigate();
    // @ts-ignore
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const navigateToFillOutSurvey = () => {
        navigate("/fillOutSurvey")
    }

    return (
        <>
            <div style={{ background: "whitesmoke" }}>
                <Row justify={"center"}>
                    <Col span={24} style={{ display: "flex", alignItems: "center", color: "#4D590D", fontSize: 40, flexDirection: "column", textAlign: "center" }}>
                        <p>Inicio de Phocus - Survey</p>
                    </Col>
                </Row>
            </div>
            <Row justify="center" gutter={[16, 16]} style={{ margin: '20px' }}>
                <Col xs={24} sm={8} md={6}>
                    <Button type="primary" block onClick={navigateToFillOutSurvey}>
                        Responder Encuesta
                    </Button>
                </Col>
            </Row>
        </>
    )
}
