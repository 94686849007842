import {IAnswer} from "../../interfaces/IAnswer";
import {ISurveyQuestion} from "../../interfaces/ISurveyQuestion";

export const testAnswer1 = {
    answerid: 1,
    answer: "Primera pregunta",
    questionid: {questionid: 1}
}

export const testAnswer2 = {
    answerid: 2,
    answer: "Segunda pregunta",
    questionid: {questionid: 2}
}

export const chartTypeTest1 = {charttypeid: 1, charttype: "Tarta"}
export const chartTypeTest2 = {charttypeid: 2, charttype: "Barras"}
export const chartTypeTestList = [chartTypeTest1, chartTypeTest2]
export const questionTypeTest1 = {questiontypeid: 1, questiontype: "Simple"}
export const questionTypeTest2 = {questiontypeid: 2, questiontype: "Multiple"}
export const questionTypeTestList = [questionTypeTest1, questionTypeTest2]
export const testQuestion1 = {
    questionid: 1,
    question: "Primera pregunta",
    block: {blockid: 1, block: "Primer Bloque"},
    questionType: questionTypeTest1,
    chartType: chartTypeTest1,
    possibleAnswers: [testAnswer1],
    mandatory: true,
    restrictAnswerToPossibleOnly: false
}
export const testQuestion2 = {
    questionid: 2,
    question: "Segunda pregunta",
    block: {blockid: 2, block: "Segundo Bloque"},
    questionType: questionTypeTest2,
    chartType: chartTypeTest2,
    possibleAnswers: [testAnswer2],
    mandatory: false,
    restrictAnswerToPossibleOnly: true
}
export const testBlock1 =
    {
    blockid: 1,
    block: "Primer Bloque",
    questions: [testQuestion1]
    }

export const testBlock2 = {
    blockid: 2,
    block: "Segundo Bloque",
    questions: [testQuestion2]
}
export const testBlocks = [testBlock1, testBlock2]

export const testSurvey1 = {
    surveyid: 1,
    surveyTitle: "Primera Encuesta",
    blocks: testBlocks
}

export const testSurvey2 = {
    surveyid: 2,
    surveyTitle: "Segunda Encuesta",
    blocks: testBlocks
}

export const testSurveys = [testSurvey1, testSurvey2]