import React from "react";
import {Button, Col, Form, Input, message, Row} from "antd";
import styles from "./LoginForm.module.css";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {login} from "../../Controller/redux/auth/actions";

type FieldType = {
    email?: string;
    password?:string;
};
export default function LoginForm({loginSuccessCallback, loginErrorCallback}:{loginSuccessCallback: Function, loginErrorCallback?: Function}){
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    function handleLogin(values: {email: string, password: string}) {
        form.validateFields().then(()=>{
            console.log("Hanlde login success")
            // @ts-ignore
            dispatch(login(values.email, values.password, null, loginSuccessCallback, loginErrorCallback ? loginErrorCallback : (errorMessgage) => {
                message.error(errorMessgage ? errorMessgage : "Error iniciando sesión, revise sus datos")
            }))
        }).catch(()=>{
            message.error('Solucione los errores del formulario.')
        })
    }

    return (
        <>
            <Row justify={'center'}>
                <h1 className={styles.title}>ACCEDE A TU PERFIL</h1>
            </Row>
                <Form
                    form={form}
                    name="login"
                    labelCol={{ span: 24}}
                    wrapperCol={{ span: 24 }}
                    style={{ width: '100%' }}
                    initialValues={{ remember: true }}
                    onFinish={handleLogin}
                    //onFinishFailed={handleLogin}
                    autoComplete="off"
                >
                    <Row justify={'center'}>
                        <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                            <Form.Item<FieldType>
                                label={<span className={styles.label}>CORREO ELECTRÓNICO</span>}
                                name="email"
                                rules={[{ required: true, message: 'Debe introducir un usuario' }]}
                                style={{ width: '100%' }}
                            >
                                <Input className={styles.input} placeholder={''} style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify={'center'}>
                        <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                            <Form.Item<FieldType>
                                label={<span className={styles.label}>CONTRASEÑA</span>}
                                name="password"
                                rules={[{ required: true, message: 'Debe introducir una contraseña' }, { type: 'string', message: 'Contraseña no válida' }]}
                                style={{ width: '100%' }}
                            >
                                <Input type={'password'} className={styles.input} placeholder={''} style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row justify={'center'} style={{paddingTop:30}}>
                        <Link style={{color:'black'}} to={''}>¿Has olvidado tu contraseña?</Link>
                    </Row>
                    <Row justify={'center'} style={{paddingTop:30}}>
                        <Button type={"default"} htmlType="submit" className={'defaultButton'}>CONTINUAR</Button>
                    </Row>
                </Form>

            </>
    );
}