import {
    DASHBOARD_FETCHED,
    DASHBOARD_SET_ACTIVE_DASHBOARD,
    DASHBOARD_SHOW_FILTERS,
    DASHBOARD_SHOW_TIME_PERIOD_DIALOG,
    DASHBOARD_TIMEPERIODS_FETCHED,
    DASHBOARD_WIDGET_DATA_FETCHED,
    DASHBOARD_WIDGET_FETCHED,
    DASHBOARD_WIDGETS_FETCHED
} from './actions'

let initialState = {
    dashboards: [],
    dashboardsToShow: [],
    dashboardsFetched: false,
    activeDashboard: {},
    widgets: {},
    widgetsFetched: false,
    widgetsDatum: {},
    widgetsRefreshedAt: {},
    timePeriods: [],
    criteria: {},
    filtersVisible: false,
    settingsPopUpVisible: false,
    timePeriodDialogVisible: false
}

export const dashboard = (state = initialState, action) => {
    const time = new Date().getTime();
    switch (action.type) {
        case DASHBOARD_FETCHED:
            return {...state, dashboards: action.dashboards, dashboardsToShow: action.dashboardsToShow, dashboardsFetched: true}
        case DASHBOARD_SET_ACTIVE_DASHBOARD:
            return {...state, activeDashboard: action.dashboard}
        case DASHBOARD_WIDGET_FETCHED:
            state.widgets[action.widget.widgetid] = action.widget;
            state.widgetsRefreshedAt[action.widget.widgetid] = time;
            return {...state}
        case DASHBOARD_WIDGET_DATA_FETCHED:
            state.widgetsDatum[action.widgetId] = action.data;
            state.widgetsRefreshedAt[action.widgetId] = time;
            return {...state};
        case DASHBOARD_WIDGETS_FETCHED:
            return {...state, widgets: action.widgets, widgetsFetched: true}
        case DASHBOARD_TIMEPERIODS_FETCHED:
            return {...state, timePeriods: action.timePeriods}
        case DASHBOARD_SHOW_FILTERS:
            return {...state, filtersVisible: action.visible}
        case DASHBOARD_SHOW_TIME_PERIOD_DIALOG:
            return {...state, timePeriodDialogVisible: action.visible}
        default:
            return state;
    }
}