import React, {useEffect, useState} from 'react';
import RestClient from "../../Controller/rest/RestClient";
import {rootServiceURL} from "../../Controller";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {ISurveyItem} from "../../interfaces/ISurveyItem";
import QuestionGroupDetail from "./QuestionGroupDetail";
import SurveyQuestionDetail from "./SurveyQuestionDetail";


export default function QuestionDetail(){

    const dispatch = useDispatch();

    const { persistibleitemid } = useParams();
    // @ts-ignore
    const auth = useSelector(state => state.auth);
    const [question, setQuestion] = useState<ISurveyItem | undefined>(undefined);

    useEffect(() => {
        loadQuestion();

        return () => {
        };
    }, [persistibleitemid]);

    function loadQuestion () {
        const token = auth.token;

        const restClient = new RestClient(dispatch)
            .token(token).url(rootServiceURL() +"phocusservice/question/" + persistibleitemid);

        restClient.doGet((result: ISurveyItem) => {
            if (result) {
                setQuestion(result)
            }
        });
    }

    return (
        <>
            {question && (question.surveyitemtype === "QG" || question.surveyitemtype === "questiongroup") ? <QuestionGroupDetail question={question} loadQuestion={loadQuestion}/> : null}

            {question && !(question.surveyitemtype === "QG" || question.surveyitemtype === "questiongroup") ? <SurveyQuestionDetail question={question} loadQuestion={loadQuestion}/> : null}
        </>
    );
}