import React from "react";
import {Md5} from "ts-md5";

export function formatDateWithoutTime(date:Date){
    let formattedDate = date;
    formattedDate.setMilliseconds(0);
    formattedDate.setSeconds(0);
    formattedDate.setMinutes(0);
    formattedDate.setHours(0);
    return formattedDate;
}
export function formatCurrency(value: number){
    if(value !== undefined){
        return value.toLocaleString('es-ES', {style: 'currency', currency: 'EUR'});
    }
    return "";
}
export function getNormalizedText(value:string):string{
    return value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
}

export function filterSelectOptions(){
    return (inputValue: string, option?: { label: any; }) => (getNormalizedText(option?.label ?? '')).toLowerCase().includes(getNormalizedText(inputValue))
}


export function getNumberParser() {
    return (value: string | undefined) => value ? value.replace(",", ".") : "";
}

export function generateUuid() : string {
    const frontId = Math.random().toString() +
        Math.random().toString() +
        Math.random().toString();
    return frontId;
}

export function deleteUuid() {
    localStorage.removeItem('frontId');
}

export const encryptMD5 = (text: string) : string => {
    return Md5.hashStr(text);
}

export function randomString(length: number) {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return randomStringWithSpecificChars(length, chars);
}
export function randomStringWithSpecificChars(length: number, chars: string) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
    return result;
}
// @ts-ignore
export function refreshQR(seconds:number,totalSeconds:number,setSeconds:React.Dispatch<React.SetStateAction<number>>, timeOutAction){
    //console.log("refreshQR seconds "+seconds+", "+totalSeconds)

    const timer = setTimeout(() => {
        //console.log("Set timeout seconds "+seconds+", "+totalSeconds)

        if (seconds > 0) {
            setSeconds(seconds - 1);
        }else{
            setSeconds(totalSeconds);
            timeOutAction && timeOutAction();
        }
    }, 1000);
    return () => clearTimeout(timer);
}
export function openModal(setModalOpen:React.Dispatch<boolean>){
    setModalOpen(true);
}
export function closeModal(setModalOpen:React.Dispatch<boolean>){
    setModalOpen(false);
}