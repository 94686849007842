import React, {useEffect, useState} from 'react';
import {
    Button,
    Form,
    Input,
    Modal,
    Space,
    message, Popconfirm, Select, Row, Col
} from "antd";
import PhocusTable from "../../components/PhocusTable/PhocusTable";
import RestClient from "../../Controller/rest/RestClient";
import {rootServiceURL} from "../../Controller";
import {IField} from "../../interfaces/IField";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {deleteQuestionGroup, deleteSurveyQuestion} from "./actions";
import {IBlock} from "../../interfaces/IBlock";
import {IQuestionType} from "../../interfaces/IQuestionType";
import {IChartType} from "../../interfaces/IChartType";
import {getSurveyItemTypeRender} from "../../components/AntdUtils";
import {ISurveyItem} from "../../interfaces/ISurveyItem";
import './survey.css'


export default function QuestionListByBlock(){

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { blockid } = useParams();
    // @ts-ignore
    const auth = useSelector(state => state.auth);
    const [block, setBlock] = useState<IBlock | undefined>(undefined);
    const [list, setList] = useState<ISurveyItem[]>([])
    const [questionTypeList, setQuestionTypeList] = useState<IQuestionType[]>([])
    const [chartTypeList, setChartTypeList] = useState<IChartType[]>([])
    const surveyItemTypeList = ["surveyquestion", "questiongroup"]
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updateFieldValues, setUpdateFieldValues] = useState<ISurveyItem | undefined>(undefined);

    useEffect(() => {
        loadBlock();

        return () => {
        };
    }, []);

    const showModal = () => {
        setIsModalOpen(true);
    };

    function loadBlock () {
        const token = auth.token;

        const restClient = new RestClient(dispatch)
            .token(token).url(rootServiceURL() +"phocusservice/block/" + blockid);

        restClient.doGet((result: IBlock) => {
            if (result) {
                setBlock(result)
                const restTimeSlotClient = new RestClient(dispatch)
                    .token(token).url(rootServiceURL() +"phocusservice/questionsByBlockid/" + blockid);
                restTimeSlotClient.doGet((result: ISurveyItem[]) => {
                    console.log(result)
                    result && setList(result);
                })
            }
        });
    }

    const columns : IField<ISurveyItem>[]=
        [
            {
                title: 'Código',
                dataIndex: 'persistibleitemid',
                key: 'persistibleitemid',
                show:false,
                update:false,
                sorter: (a, b) => a.persistibleitemid > b.persistibleitemid ? 1 : -1,
            },
            {
                title: 'Orden',
                dataIndex: 'surveyitemorder',
                key: 'surveyitemorder',
                show:true,
                update:true,
                rules: [{ required: true, message: 'Please input order' }],
                sorter: (a, b) => a.surveyitemorder > b.surveyitemorder ? 1 : -1,
            },
            {
                title: 'Pregunta',
                dataIndex: 'caption',
                key: 'caption',
                show:true,
                update:true,
                rules: [{ required: true, message: 'Please input question' }],
                sorter: (a, b) => a.caption > b.caption ? 1 : -1,
            },
            {
                title: 'Tipo',
                dataIndex: 'surveyitemtype',
                key: 'surveyitemtype',
                show:true,
                update:false,
                sorter: (a, b) => a.surveyitemtype > b.surveyitemtype ? 1 : -1,
                render: getSurveyItemTypeRender()
            },
            {
                title: 'Actions',
                key: 'actions',
                render: (value, record) => {
                    return (
                        <Space size="middle" className="actions-column">
                            <a onClick={() => openUpdateModal(record)
                            }>Editar </a>
                            <a onClick={() => navigate("/questionDetail/"+record.persistibleitemid)
                            }>Detalle </a>
                            <Popconfirm
                                placement="left"
                                title="Eliminar Pregunta"
                                description={<>
                                    <p>
                                        "¿Está seguro de que quiere eliminar esta Pregunta?"
                                    </p>

                                </>}
                                onConfirm={() => {
                                    if (record.surveyitemtype === "questiongroup" || record.surveyitemtype === "QG") {
                                        deleteQuestionGroup(record.persistibleitemid, auth, dispatch, () => {loadBlock()})
                                    } else {
                                        deleteSurveyQuestion(record.persistibleitemid, auth, dispatch, () => {loadBlock()})
                                    }

                                }}
                                onCancel={() => {}}
                                okText="Si"
                                cancelText="No"
                            >
                                <Button>Eliminar</Button>
                            </Popconfirm>
                        </Space>
                    );
                },
            }
        ];

    const mobileColumns : IField<ISurveyItem>[]=
        [
            {
                title: 'Código',
                dataIndex: 'persistibleitemid',
                key: 'persistibleitemid',
                show:false,
                update:false,
                sorter: (a, b) => a.persistibleitemid > b.persistibleitemid ? 1 : -1,
            },
            {
                title: 'Orden y Pregunta',
                dataIndex: 'surveyitemorder',
                key: 'surveyitemorder',
                show:true,
                update:true,
                rules: [{ required: true, message: 'Please input order' }],
                sorter: (a, b) => a.surveyitemorder > b.surveyitemorder ? 1 : -1,
                render: (value, record) => {
                    if (record) {
                        return (
                            <Space>
                                <p>{record.surveyitemorder}</p>
                                <p>{record.caption}</p>
                            </Space>
                        )
                    }
                }
            },
            {
                title: 'Pregunta',
                dataIndex: 'caption',
                key: 'caption',
                show:false,
                update:true,
                rules: [{ required: true, message: 'Please input question' }],
                sorter: (a, b) => a.caption > b.caption ? 1 : -1,
            },
            {
                title: 'Tipo',
                dataIndex: 'surveyitemtype',
                key: 'surveyitemtype',
                show:true,
                update:false,
                sorter: (a, b) => a.surveyitemtype > b.surveyitemtype ? 1 : -1,
                render: getSurveyItemTypeRender()
            },
            {
                title: 'Actions',
                key: 'actions',
                render: (value, record) => {
                    return (
                        <Space size="middle" className="actions-column">
                            <a onClick={() => openUpdateModal(record)
                            }>Editar </a>
                            <a onClick={() => navigate("/questionDetail/"+record.persistibleitemid)
                            }>Detalle </a>
                            <Popconfirm
                                placement="left"
                                title="Eliminar Pregunta"
                                description={<>
                                    <p>
                                        "¿Está seguro de que quiere eliminar esta Pregunta?"
                                    </p>

                                </>}
                                onConfirm={() => {
                                    if (record.surveyitemtype === "questiongroup" || record.surveyitemtype === "QG") {
                                        deleteQuestionGroup(record.persistibleitemid, auth, dispatch, () => {loadBlock()})
                                    } else {
                                        deleteSurveyQuestion(record.persistibleitemid, auth, dispatch, () => {loadBlock()})
                                    }

                                }}
                                onCancel={() => {}}
                                okText="Si"
                                cancelText="No"
                            >
                                <Button>Eliminar</Button>
                            </Popconfirm>
                        </Space>
                    );
                },
            }
        ];

    const openUpdateModal = (entity: ISurveyItem) => {
        cleanFormData();

        //@ts-ignore
        const updatedFieldValues :ISurveyItem= {};

        Object.keys(entity)?.forEach(field => {

            if("surveyitemtype" === field){
                const typefield = entity[field] == "QG" || entity[field] == "questiongroup" ? "questiongroup" : "surveyquestion"
                //@ts-ignore
                updatedFieldValues[field] = entity[field];
                updatedFieldValues.type = typefield

                //@ts-ignore
                form.setFieldValue(field, entity[field]);
                form.setFieldValue("type", typefield)
            } else{
                //@ts-ignore
                updatedFieldValues[field] = entity[field];

                //@ts-ignore
                form.setFieldValue(field, entity[field]);
            }
        });
        setUpdateFieldValues(updatedFieldValues);
        setIsModalOpen(true)
    }

    const cleanFormData = () => {
        form.resetFields();
        setUpdateFieldValues(undefined);
    }

    const onFinish = (values : ISurveyItem) => {
        let entityToPost = {
            ...updateFieldValues,
            ...values,
            blockid: {blockid: blockid}
        };

        const urltype = entityToPost.surveyitemtype == "questiongroup" ? "questiongroup" : "surveyquestion"
        const url = rootServiceURL() + 'phocusservice/' + urltype;

        const token = auth.token;

        const rc = new RestClient(dispatch).token(token).url(url);

        const update = updateFieldValues && updateFieldValues!.persistibleitemid !== undefined && updateFieldValues!.persistibleitemid > 0;
        if(!update){
            console.log("Creando Pregunta: " ,entityToPost)

            rc.doPost(entityToPost, (result: any) => {
                if (rc.isSuccess()) {
                    message.success("Pregunta creada correctamente")
                    loadBlock();
                    setIsModalOpen(false);
                    cleanFormData();
                }
                else{
                    message.error( "Error creando Pregunta "+rc.getErrors());
                }

            }, false);

        } else {
            rc.doPost(entityToPost, (result: any) => {
                if (rc.isSuccess()) {
                    message.success("Pregunta actualizada correctamente");
                    loadBlock();
                    setIsModalOpen(false);
                    cleanFormData();
                }
                else{
                    message.error( "Error actualizando Pregunta "+rc.getErrors());
                }

            }, false);
        }
    }

    return (
        <>
            <div style={{background: "whitesmoke"}}>
                <Row justify={"center"}>
                    <Col span={24} style={{display:"flex", alignItems: "center", color: "#4D590D", fontSize: 40, flexDirection: "column"}}>
                        <p>Bloque: {block?.block}</p>
                    </Col>
                </Row>
            </div>
            <Modal
                title={"Modificar Pregunta"}
                open={isModalOpen}
                footer={null}
                onCancel={() => {
                    cleanFormData();
                    setIsModalOpen(false); }}>
                <Form
                    name="basic"
                    form={form}
                    initialValues={{ remember: false }}
                    onFinish={(values) => onFinish(values)}
                    scrollToFirstError
                    autoComplete="off">
                    {columns.filter(field =>(field.update)).map(field => (
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label={""+field.title}
                            name={field.key as string}
                            rules={field.rules}
                            valuePropName={field.valuePropName}
                        >
                            {field.renderInput ? field.renderInput(field.key as string) : <Input/>}
                        </Form.Item>
                    ))}
                    <Form.Item
                        labelCol={{ span: 24 }}
                        label={"Tipo de Pregunta"}
                        name={"surveyitemtype"}
                        rules={[{ required: true, message: 'Please input a Question Type' }]}
                    >
                        <Select
                            style={{ width: '100%' }}
                            showSearch
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                            placeholder="Seleccione un Tipo de Pregunta"
                            options={surveyItemTypeList.map(surveyItem => ({
                                label: surveyItem == "questiongroup" ? "Grupo de Preguntas" : "Pregunta simple",
                                value: surveyItem
                            }))}
                        />
                    </Form.Item>
                    <Button type="primary" htmlType="submit">
                        Actualizar
                    </Button>
                </Form>
            </Modal>
            <Button type="primary" onClick={showModal} style={{margin: '20px'}}>
                Crear nueva Pregunta
            </Button>
            <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
                <PhocusTable columns={columns} dataSource={list} />
            </Col>
            <Col offset={0} xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
                <PhocusTable columns={mobileColumns} dataSource={list} />
            </Col>
        </>
    );
}