import { useLocation, Navigate } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React from "react";

export function RequireAuth({ children }: { children: JSX.Element }) {
    let location = useLocation();
    // @ts-ignore
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    if (!auth || !auth.token || auth.token.length <= 0) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    } else {
        const childrenWithProps = React.cloneElement(children as React.ReactElement, {
            auth: auth,
            dispatch: dispatch
        });
        return childrenWithProps;
    }
}