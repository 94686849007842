import {nextNotificationId} from './NotificationController'

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const ACK_NOTIFICATION = 'ACK_NOTIFICATION'

export const newNotification = (notification) => (
    dispatch => {
        notification.id = nextNotificationId();
        dispatch(notificationAdded(notification))
    }
)

export const notificationAdded = notification => ({type: ADD_NOTIFICATION, notification: notification})

export const ackNotification = notification => ({type: ACK_NOTIFICATION, notification: notification})