import styles from "./LoginRegister.module.css";
import React, { useEffect, useState} from 'react';
import LoginForm from "../../components/loginForm/LoginForm";
import {Form, message} from "antd";
import {useNavigate} from "react-router-dom";

export default function LoginRegisterUser(){
    const [isLoginVisible, setIsLoginVisible] = useState(true);
    const [form] = Form.useForm();
    const navigate = useNavigate();

    return (
        <div className={styles.loginBackground}>
            <div style={{paddingTop:30}}></div>
            {isLoginVisible?(
                <LoginForm loginSuccessCallback={() => {
                    message.success('Sesión iniciada correctamente');
                    navigate("/home");
                }}></LoginForm>
            ):null}

        </div>
    );
}