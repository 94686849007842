// import {push} from 'connected-react-router'
import {clearPendingRequest} from '../desktop/actions'
import Cookies from 'universal-cookie';
import RestClient from '../../rest/RestClient'
import {CONFIG} from "../../Settings";
import {loggedUser} from "../../index";
import {encryptMD5} from "../../../solutions/Utils";

export const AUTH_LOGIN_REQUESTED = 'AUTH_LOGIN_REQUESTED'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_LOGGED = 'AUTH_LOGGED'
export const AUTH_LOGIN_FAILED = 'AUTH_LOGIN_FAILED'
export const AUTH_LOGIN_FAILED_ACK = 'AUTH_LOGIN_FAILED_ACK'
export const AUTH_RESTORE_SESSION = 'AUTH_RESTORE_SESSION'
export const AUTH_SESSION_EXPIRED = 'AUTH_SESSION_EXPIRED'
export const AUTH_DONT_ASK_FOR_LOGIN = 'AUTH_DONT_ASK_FOR_LOGIN'
export const AUTH_SWITCH_ROLES_TEMPORARY = 'AUTH_SWITCH_ROLES_TEMPORARY'

export const loginRequested = () => {
    return {
        type: AUTH_LOGIN_REQUESTED
    }
}
//@ts-ignore
export const login = (user, password, pretoken, redirectTo, redirectOnFail) => {
    const cookies = new Cookies();
    // @ts-ignore
    return (dispatch) => {
        cookies.remove("token", {path: "/"});
        cookies.remove("phocusUser", {path: "/"});
        dispatch(loginRequested());
        // const encrypted = encrypt(password);
        // let loginRequest = {
        //     username: user,
        //     password: encrypted[0],
        //     passwordEncryption: 'phocus',
        //     guid: encrypted[1],
        //     pretoken: pretoken
        // };

        const loginRequest = {
            username: user,
            password: encryptMD5(password),
            passwordEncryption: "MD5",
        };

        var restClient = new RestClient(dispatch, false, true)
            .url(CONFIG.restService.restLoginUrl);

        restClient.doPost(loginRequest, (response) => {
            if (response && response.success) {
                cookies.set("token", response.token, {path: "/"});
                cookies.set("phocusUser", JSON.stringify(response.phocusUser), {path: "/"});
                loggedUser.phocusUser = {...response.phocusUser, token: response.token};
                RestClient._unauthenticated = false;
                dispatch(logged({phocusUser: response.phocusUser, token: response.token}))
                redirectTo && redirectTo();
                // const pendingRequests = getState().desktop.pendingRequests;
                // const obtainedResponse = response;
                // const timers = [];
                // pendingRequests.map((pendingRequest, index) => {
                //     const thisPendingRequest = pendingRequest;
                //     const thisIndex = index;
                //     timers[thisIndex] = setTimeout(() => {
                //         dispatch(clearPendingRequest(thisIndex))
                //         thisPendingRequest.request.headers.set("token", obtainedResponse.token);
                //         new RestClient(dispatch).url(thisPendingRequest.url).processRequest(thisPendingRequest.request, (...params) => {
                //             thisPendingRequest.callback && thisPendingRequest.callback(...params);
                //         });
                //         clearTimeout(timers[thisIndex])
                //     }, 500)
                // })
                // redirectTo && dispatch(push(redirectTo))
            } else {
                var message = response ? response.message : restClient.getErrors();
                redirectOnFail && redirectOnFail(message);
                // redirectOnFail && dispatch(push(redirectOnFail));

                dispatch(loginFailed(message));
            }
        }, false);
    }
}

export const logged = (authData) => {
    return {
        type: AUTH_LOGGED,
        authData: authData
    }
}

export const loginFailed = (message) => {
    return {
        type: AUTH_LOGIN_FAILED,
        message: message
    }
}

export const loginFailedAck = () => {
    return {
        type: AUTH_LOGIN_FAILED_ACK
    }
}

export const logoutAction = () => {
    return {
        type: AUTH_LOGOUT
    }
}

export const sessionExpiredAction = () => {
    return {
        type: AUTH_SESSION_EXPIRED
    }
}

export const logout = () => {
    return (dispatch) => {
        const timeout = setTimeout(() => {
            document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            document.cookie = 'phocusUser=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            clearTimeout(timeout);
        }, 500);
        dispatch(logoutAction());
    }
}

export const restoreSession = (token, phocusUser) => {
    return {
        type: AUTH_RESTORE_SESSION,
        token: token,
        phocusUser: phocusUser
    }
}

export const switchRoles = (newRoles) => {
    return {
        type: AUTH_SWITCH_ROLES_TEMPORARY,
        newRoles: newRoles
    }
}

export const dontAskForLogin = () => {
    return {
        type: AUTH_DONT_ASK_FOR_LOGIN
    }
}