import React, {useEffect, useState} from "react";
import RestClient from "../../Controller/rest/RestClient";
import {useDispatch, useSelector} from "react-redux";
import {rootServiceURL} from "../../Controller";
import {Button, Checkbox, Col, Form, Input, Row, Select} from "antd";
import {ISurvey} from "../../interfaces/ISurvey";
import SurveyToFillOut from "./SurveyToFillOut";

export default function SelectSurveyToFillOut() {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [surveyList, setSurveyList] = useState<ISurvey[]>([])
    const [surveyid, setSurveyid] = useState(0);

    useEffect(() => {
        loadSurveyList();

        return () => {
        };
    }, []);

    function loadSurveyList () {
        const restClient = new RestClient(dispatch).url(rootServiceURL() +"phocusservice/surveyWithoutAuth");
        restClient.doGet((result: []) => {
            result && setSurveyList(result)
        });

    }

    const onFinish = (values : ISurvey) => {
        setSurveyid(values.surveyid)
    }
    return (
        <>
            {
                surveyid == 0 ?
                    <>
                    <div style={{background: "whitesmoke"}}>
                        <Row justify={"center"}>
                            <Col span={24} style={{display:"flex", alignItems: "center", color: "#4D590D", fontSize: 40, flexDirection: "column"}}>
                                <p>Página de selección de encuesta</p>
                            </Col>
                        </Row>
                    </div>

                    <Form
                        name="basic"
                        form={form}
                        initialValues={{ remember: false }}
                        onFinish={(values) => onFinish(values)}
                        scrollToFirstError
                        autoComplete="off"
                        style={{margin: '20px'}}
                    >
                        <Form.Item
                            labelCol={{ span: 24 }}
                            label={"Seleccione encuesta"}
                            name={"surveyid"}
                        >
                            <Select
                                options={surveyList.map(survey => {
                                    return {
                                        label: survey.surveyTitle,
                                        value: survey.surveyid
                                    }
                                })}/>
                        </Form.Item>

                        <Button type="primary" htmlType="submit">
                            Rellenar encuesta
                        </Button>
                    </Form>
                    </>
                :
                <SurveyToFillOut surveyid={surveyid}/>
            }

        </>
    )
}