import {
    SET_SOLUTIONS_CONFIGURATION
} from './actions';

const initialState = {
    solutions: {}
}

export const settings = (state = initialState, action) => {
    switch (action.type) {
        case SET_SOLUTIONS_CONFIGURATION:
            return {
                ...state,
                solutions: action.solutions
            };
        default:
            return state;
    }
}