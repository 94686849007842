// export const serverName = localStorage.getItem("preferences.server") || window.location.hostname + ":8080";
export const serverName = window.location.hostname == "localhost" ? "http://localhost:8080" : window.location.hostname + ":8443";
export const server = serverName.startsWith("http") ? serverName + (serverName.endsWith("/") ? "" : "/") : "https://" + serverName + "/";

//export const server = "http://localhost:8080/";
 //export const server = "http://adimur.iten.es:8443/"

export const imagesPath = (serverName.includes("dev.promotor.tixxlab") || serverName.includes("localhost")) ? "https://dev.tixxlab.com/" : "https://www.tixxlab.com/";

export const CONFIG = {
    firstScreen: "Dashboard",
    restService: {
        restLoginUrl: server + "api/phocusservice/login",
        restRegistrationUrl: "",
        restServiceRoot: server + "api/",
        restTXLServiceRoot: server + "PhocusTXL/",
        kpiUrl: server + "api/",
        uiService: "ui/",
        dataService: "rest/",
        dataWriteService: "rest/",
        dataServiceKPI: "restKPI/",
        completeGraphDataService: "rest/",
        fullGraphDataService: "rest/",
        uploadFileService: "fileservice/",
        reportService: "reportingService/",
        /*ws: "wss://" + (serverName.includes("dev.tixxlab") ? "dev.tixxlab.com:8443" : "www.tixxlab.com:8443") + "/PhocusTXL/phocus",
        wsKpi: "ws://" + serverName + "/phocus-kpi/phocus"*/

    }, firebaseSenderId: "313056414449"
}

export const CUSTOM_SERVICES_FOR_ENTITIES = {}

export const CUSTOM_SERVICES_FOR_REPORTS = {}

export const CUSTOM_FIELD_RENDERERS = {}
