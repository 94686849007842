import RestClient from "../../rest/RestClient"
import {dataServiceURLForCompleteGraphEntity, dataServiceURLForEntity, UIURLForEntity}  from '../../index'
import {mixFormDefinitions} from "../../forms/FormsController"
import {newNotification} from "../notification/actions"

export const FORMS_REGISTER_FORM = 'FORMS_REGISTER_FORM'
export const FORMS_UI_DEFINITION_FETCHED = 'FORMS_UI_DEFINITION_FETCHED'
export const FORMS_POSSIBLE_VALUES_FETCHED = 'FORMS_POSSIBLE_VALUES_FETCHED'
export const FORMS_SEARCH_RESULTS_FETCHED = 'FORMS_SEARCH_RESULTS_FETCHED'
export const FORMS_SET_RECORDS = 'FORMS_SET_RECORDS'
export const FORMS_SELECT_RECORD = 'FORMS_SELECT_RECORD'
export const FORMS_RECORD_ADDED = 'FORMS_RECORD_ADDED'
export const FORMS_RECORD_UPDATED = 'FORMS_RECORD_UPDATED'
export const FORMS_RECORD_REMOVED = 'FORMS_RECORD_REMOVED'

export const fetchUIDefinition = (entityName, callback) => {
    return (dispatch, getState) => {
        const existentDefinition = getState().forms.ui[entityName];
        if (!existentDefinition) {
            const urlToFetch = UIURLForEntity(entityName);
            let rc = new RestClient(dispatch).url(urlToFetch).token(getState().auth.token);
            rc.doGet((definition) => {
                if (definition) {
                    definition = mixFormDefinitions(entityName, definition)
                    if (definition.details) {
                        definition.details.forEach(detail => detail.entityName && dispatch(fetchUIDefinition(detail.entityName)));
                    }
                    dispatch(uiDefinitionFetched(entityName, definition))
                    callback && callback(definition)
                }
            })
        } else {
            callback && callback(existentDefinition)
        }
    }
}

export const uiDefinitionFetched = (entityName, definition) => ({
    type: FORMS_UI_DEFINITION_FETCHED,
    entityName: entityName,
    definition: definition
});

export const fetchPossibleValues = (entityName, key, criteria, completeGraph, serviceURL, primaryKeyField, callback) => {
    const processFetchedValues = function (possibleValues, getState, entityName, primaryKeyName, dispatch) {
        if (possibleValues) {
            const possibleValuesObject = getState().forms.possibleValues[entityName] || {};
            possibleValues.forEach((item) => possibleValuesObject[item[primaryKeyName]] = item);
            dispatch(possibleValuesFetched(entityName, possibleValuesObject))
        }
        callback && callback(possibleValues);
    };

    const getPossibleValues = (entityName, key, getState, dispatch) => {
        const possibleValues = getState().forms.possibleValues[entityName];
        /****
         * TODO: Next is a sort of cache for possible values.
         * But it should be refreshed by notifications from server when removing or updating entities...
         */
        if (key && possibleValues && possibleValues[key]) {
            callback && callback([possibleValues[key]]);
        } else {
            let urlToFetch;
            if (completeGraph) {
                urlToFetch = dataServiceURLForCompleteGraphEntity() + "possibleValues/" + entityName + (key ? "/" + key : "");
            } else {
                urlToFetch = dataServiceURLForEntity() + "possibleValues/" + entityName + (key ? "/" + key : "");
            }
            const primaryKeyName = getState().forms.ui[entityName].primaryKeyName;
            let rc = new RestClient(dispatch).url(urlToFetch).token(getState().auth.token);
            if (criteria && key) {
                console.warn("Incorrect use of fetchPossibleValues: cannot provide key and criteria at the same time.");
            } else if (!criteria && !key) {
                console.warn("Incorrect use of fetchPossibleValues: either criteria or key should be used.");
            }
            if (key) {
                if (serviceURL) {
                    const criteriaKey = {
                        criteriaItems: [{
                            fieldName: primaryKeyField,
                            comparisonOperator: "OPERATOR_EQUALS",
                            fieldValue: key
                        }]
                    };
                    let rc = new RestClient(dispatch).url(serviceURL).token(getState().auth.token);
                    rc.doPost(criteriaKey, possibleValues => {
                        processFetchedValues(possibleValues, getState, entityName, primaryKeyName, dispatch);
                    })
                } else {
                    rc.doGet(possibleValues => {
                        processFetchedValues(possibleValues, getState, entityName, primaryKeyName, dispatch);
                    })
                }
            } else if (criteria) {
                rc.doPost(criteria, possibleValues => {
                    processFetchedValues(possibleValues, getState, entityName, primaryKeyName, dispatch);
                }, false)
            }
        }
    }

    return (dispatch, getState) => {
        const token = getState().auth.token;
        if (!getState().forms.ui[entityName]) {
            const urlToFetch = UIURLForEntity(entityName);
            let rc = new RestClient(dispatch).url(urlToFetch).token(token);
            rc.doGet((definition) => {
                if (definition) {
                    definition = mixFormDefinitions(entityName, definition)
                    if (definition.details) {
                        definition.details.map((detail) => dispatch(fetchUIDefinition(detail.entityName)));
                    }
                    dispatch(uiDefinitionFetched(entityName, definition))
                    getPossibleValues(entityName, key, getState, dispatch)
                }
            })
        } else {
            getPossibleValues(entityName, key, getState, dispatch)
        }
    }
}

export const possibleValuesFetched = (entityName, possibleValues) => ({
    type: FORMS_POSSIBLE_VALUES_FETCHED,
    entityName: entityName,
    possibleValues: possibleValues
})

export const fetchRecordByPrimaryKeyValue = (formId, entityName, value) => {
    return ((dispatch, getState) => {
        let token = getState().auth.token;
        if (value && value != "") {
            const url = dataServiceURLForEntity(entityName) + entityName + "/" + value;
            let rc = new RestClient(dispatch).url(url).token(token);
            rc.doGet(record => {
                if (record) {
                    dispatch(setRecords(formId, [record]));
                    dispatch(selectCurrentRecord(formId, 0));
                } else {
                    dispatch(setRecords(formId, []));
                }
            });
        } else {
            dispatch(setRecords(formId, []));
            dispatch(selectCurrentRecord(formId, 0));
        }
    })
}

export const ensureFormRegistration = (formId, entityName, callback) => {
    return (dispatch, getState) => {
        const entityNotSet = !getState().forms.entityForms[formId] || !getState().forms.entityForms[formId].entityName;
        if (entityNotSet || getState().forms.entityForms[formId].entityName != entityName) {
            dispatch(registerForm(formId, entityName));
        }
        callback && callback();
    }
}

export const registerForm = (formId, entityName) => {
    return {type: FORMS_REGISTER_FORM, formId: formId, entityName: entityName}
}

export const setRecords = (formId, records) => {
    return {type: FORMS_SET_RECORDS, formId: formId, records: records}
}

export const selectCurrentRecord = (formId, position) => {
    return {
        type: FORMS_SELECT_RECORD,
        formId: formId,
        position: position
    }
}

export const search = (formId, entityName, criteria) => {
    return (dispatch, getState) => {
        let token = getState().auth.token;
        const urlToFetch = dataServiceURLForEntity(entityName) + "search/" + entityName;
        let rc = new RestClient(dispatch).url(urlToFetch).token(token);
        rc.doPost(criteria, (results) => {
            if (results && results.length > 0) {
                dispatch(setRecords(formId, results))
                dispatch(selectCurrentRecord(formId, 0));
            } else {
                dispatch(setRecords(formId, []));
            }
        }, false)
    }
}

export const fetchDetailRecords = (uniqueId, masterUniqueId, selectedDetailIndex) => {
    return (dispatch, getState) => {
        const token = getState().auth.token;
        const masterEntityForm = getState().forms.entityForms[masterUniqueId];
        const masterEntityName = masterEntityForm.entityName;
        const primaryKeyName = getState().forms.ui[masterEntityName].primaryKeyName;
        const masterRecords = masterEntityForm && masterEntityForm.records;
        const primaryKeyValue = masterRecords && masterRecords.length > 0 && masterRecords[masterEntityForm.selectedRecord][primaryKeyName];
        if (primaryKeyValue) {
            const detailEntityName = getState().forms.ui[masterEntityName].details[selectedDetailIndex].entityName;
            const URLLastPath = masterEntityName + "/" + primaryKeyValue + "/" + detailEntityName;
            const url = dataServiceURLForEntity(detailEntityName) + URLLastPath;
            const rc = new RestClient(dispatch).url(url).token(token);
            rc.doGet((results) => {
                if (results) {
                    dispatch(setRecords(uniqueId, results));
                } else {
                    dispatch(setRecords(uniqueId, []));
                }
            })
        } else {
            dispatch(setRecords(uniqueId, []));
        }
    }
}

export const addRecord = (uniqueId, entity, callback) => {
    return (dispatch, getState) => {
        const token = getState().auth.token;
        const entityName = getState().forms.entityForms[uniqueId].entityName;
        if (entity) {
            const typeInfo = getState().forms.ui[entityName].typeInfo;
            if (typeInfo) {
                Object.keys(typeInfo).forEach((key) => {
                    entity[key] = typeInfo[key];
                })
            }
            const url = dataServiceURLForEntity(entityName) + entityName;
            const rc = new RestClient(dispatch).token(token).url(url);
            rc.doPost(entity, (locationOfNewResource) => {
                if (rc.isSuccess() && locationOfNewResource) {
                    const primaryKeyValue = locationOfNewResource.substr(locationOfNewResource.lastIndexOf("/") + 1);
                    const primaryKeyName = getState().forms.ui[entityName].primaryKeyName;
                    const addedRecord = {...entity, [primaryKeyName]: primaryKeyValue};
                    dispatch(recordAdded(addedRecord, uniqueId))
                    callback && callback(addedRecord)
                } else {
                    callback && callback(null);
                }
            });
        }
    }
}

export const recordAdded = (record, uniqueId) => ({type: FORMS_RECORD_ADDED, formId: uniqueId, record: record})

export const updateRecord = (uniqueId, entity, recordNumber, callback) => {
    return (dispatch, getState) => {
        const entityName = getState().forms.entityForms[uniqueId].entityName;
        if (entity) {
            const typeInfo = getState().forms.ui[entityName].typeInfo;
            if (typeInfo && "advisercustomer" !== entityName) {
                Object.keys(typeInfo).forEach((key) => {
                    entity[key] = typeInfo[key];
                })
            }
            const token = getState().auth.token;
            const url = dataServiceURLForEntity(entityName) + entityName;
            const rc = new RestClient(dispatch).token(token).url(url);
            rc.doPut(entity, () => {
                if (rc.isSuccess()) {
                    dispatch(recordUpdated(entity, uniqueId, recordNumber))
                }
                callback && callback(rc.isSuccess() ? entity : null)
            })
        }
    }
}

export const recordUpdated = (record, uniqueId, recordNumber) => ({
    type: FORMS_RECORD_UPDATED,
    formId: uniqueId,
    recordNumber: recordNumber,
    record: record
})

export const removeRecord = (uniqueId, row, callback) => {
    return (dispatch, getState) => {
        const token = getState().auth.token;
        const entity = getState().forms.entityForms[uniqueId].records[row];
        const entityName = getState().forms.entityForms[uniqueId].entityName;
        const primaryKeyName = getState().forms.ui[entityName].primaryKeyName;
        const primaryKeyValue = entity[primaryKeyName];
        if (primaryKeyValue) {
            const url = dataServiceURLForEntity(entityName) + entityName + "/" + primaryKeyValue;
            const rc = new RestClient(dispatch).token(token).url(url);
            rc.doDelete((result) => {
                if (rc.isSuccess()) {
                    dispatch(recordRemoved(entity, row, uniqueId))
                    callback && callback(entity)
                } else {
                    dispatch(newNotification({
                        id: 1,
                        message: "Error eliminando " + entityName + ": " + rc.getErrors()
                    }))
                }
            })
        } else {
            dispatch(newNotification({
                id: 1,
                message: "Error eliminado " + entityName + ": clave primaria no establecida. Avise al servicio técnico."
            }))
        }
    }
}

export const recordRemoved = (record, row, uniqueId) => ({
    type: FORMS_RECORD_REMOVED,
    formId: uniqueId,
    record: record,
    row: row
})