import * as auth from './auth/reducer'
import * as registration from './registration/reducer'
import * as desktop from './desktop/reducer'
import * as forms from './forms/reducer'
import * as notification from './notification/reducer'
import * as dashboard from './dashboard/reducer'
import * as settings from './settings/reducer'
import * as websocket from "./websocket/reducer";
import { combineReducers } from 'redux';

let phocusReducers = {}

Object.keys(auth).forEach(key => phocusReducers[key] = auth[key])
Object.keys(registration).forEach(key => phocusReducers[key] = registration[key])
Object.keys(desktop).forEach(key => phocusReducers[key] = desktop[key])
Object.keys(forms).forEach(key => phocusReducers[key] = forms[key])
Object.keys(notification).forEach(key => phocusReducers[key] = notification[key])
Object.keys(dashboard).forEach(key => phocusReducers[key] = dashboard[key])
Object.keys(settings).forEach(key => phocusReducers[key] = settings[key])
Object.keys(websocket).forEach(key => phocusReducers[key] = websocket[key])

export default combineReducers(phocusReducers);