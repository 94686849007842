import {
    FORMS_REGISTER_FORM,
    FORMS_UI_DEFINITION_FETCHED,
    FORMS_POSSIBLE_VALUES_FETCHED,
    FORMS_SET_RECORDS,
    FORMS_SEARCH_RESULTS_FETCHED,
    FORMS_SELECT_RECORD,
    FORMS_RECORD_ADDED,
    FORMS_RECORD_UPDATED,
    FORMS_RECORD_REMOVED
} from './actions'

const initialState = {
    ui: {},
    possibleValues: {},
    entityForms: {
        formId: {
            entityName: 'entityName',
            records: [],
            selectedRecord: 0,
            details: [
                'detailEntityName'
            ]
        }
    }
}

export const forms = (state = initialState, action) => {
    switch (action.type) {
        case FORMS_REGISTER_FORM:
            return {...state, entityForms: {...state.entityForms, [action.formId]: {entityName: action.entityName}}}
        case FORMS_UI_DEFINITION_FETCHED:
            return {...state, ui: {...state.ui, [action.entityName]: action.definition}};
        case FORMS_POSSIBLE_VALUES_FETCHED:
            return {...state, possibleValues: {...state.possibleValues, [action.entityName]: action.possibleValues}};
        case FORMS_SEARCH_RESULTS_FETCHED:
            return {
                ...state,
                entityForms: {
                    ...state.entityForms,
                    [action.formId]: {
                        ...state.entityForms[action.formId],
                        details: {
                            ...state.entityForms[action.formId].details,
                            [action.detailEntityName]: action.results
                        }
                    }
                }
            };
        case FORMS_SET_RECORDS:
            return {
                ...state, entityForms: {
                    ...state.entityForms, [action.formId]: {
                        ...state.entityForms[action.formId],
                        records: action.records,
                        selectedRecord: 0
                    }
                }
            }
        case FORMS_SELECT_RECORD:
            const newObject = {
                ...state, entityForms: {
                    ...state.entityForms,
                    [action.formId]: {
                        ...state.entityForms[action.formId],
                        selectedRecord: action.position
                    }
                }
            };
            return newObject;
        case FORMS_RECORD_ADDED:
            const newRecordsAfterAdd = [...(state.entityForms[action.formId].records || []), action.record];
            const newSelectedRecordAfterAdded = newRecordsAfterAdd.length - 1;
            return {
                ...state, entityForms: {
                    ...state.entityForms,
                    [action.formId]: {
                        ...state.entityForms[action.formId],
                        records: newRecordsAfterAdd,
                        selectedRecord: newSelectedRecordAfterAdded
                    }
                }
            }
        case FORMS_RECORD_UPDATED:
            const newRecordsAfterUpdate = [...state.entityForms[action.formId].records];
            newRecordsAfterUpdate[action.recordNumber] = action.record;
            return {
                ...state, entityForms: {
                    ...state.entityForms,
                    [action.formId]: {
                        ...state.entityForms[action.formId],
                        records: newRecordsAfterUpdate
                    }
                }
            }
        case FORMS_RECORD_REMOVED:
            const newRecordsAfterRemoved = [...state.entityForms[action.formId].records];
            newRecordsAfterRemoved.splice(action.row, 1);
            var newSelectedRecordAfterRemoved = action.row;
            if (action.row >= newRecordsAfterRemoved.length) {
                newSelectedRecordAfterRemoved = newRecordsAfterRemoved.length - 1;
            }
            return {
                ...state, entityForms: {
                    ...state.entityForms,
                    [action.formId]: {
                        ...state.entityForms[action.formId],
                        records: newRecordsAfterRemoved,
                        selectedRecord: newSelectedRecordAfterRemoved
                    }
                }
            }
        default:
            return state;
    }
}