export const CHANGE_TITLE_TO = 'CHANGE_TITLE_TO'
export const REQUEST_STARTED = 'REQUEST_STARTED'
export const REQUEST_FINISHED = 'REQUEST_FINISHED'
export const PENDING_REQUEST = 'PENDING_REQUEST'
export const CLEAR_PENDING_REQUEST = 'CLEAR_PENDING_REQUEST'
export const SET_SIDENAV_COLLAPSED = 'SET_SIDENAV_COLLAPSED'
export const SET_HIDDEN_HEADER = 'SET_HIDDEN_HEADER'

export const requestStarted = () => {
    return {
        type: REQUEST_STARTED
    }
}

export const requestFinished = () => {
    return {
        type: REQUEST_FINISHED
    }
}

export const setSideNavCollapsed = (collapsed) => {
    return {
        type: SET_SIDENAV_COLLAPSED,
        collapsed: collapsed
    }
}

export const setHiddenHeader = (hidden) => {
    return {
        type: SET_HIDDEN_HEADER,
        hidden: hidden
    }
}

export const clearPendingRequest = (pendingRequestIndex) => {
    return {
        type: CLEAR_PENDING_REQUEST,
        index: pendingRequestIndex
    }
}

export const changeTitleTo = (newTitle) => {
    return {
        type: CHANGE_TITLE_TO,
        title: newTitle
    }
}

export const pendingRequest = (url, request, callback) => {
    return {
        type: PENDING_REQUEST,
        url: url,
        request: request,
        callback: callback
    }
}