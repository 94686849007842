import React, {useEffect, useState} from 'react';
import {
    Button,
    Form,
    Input,
    Modal,
    Space,
    message, Popconfirm, Select, Row, Col, Checkbox, InputNumber
} from "antd";
import PhocusTable from "../../components/PhocusTable/PhocusTable";
import {useDispatch, useSelector} from "react-redux";
import {IAnswer} from "../../interfaces/IAnswer";
import {ISurveyItem} from "../../interfaces/ISurveyItem";
import RestClient from "../../Controller/rest/RestClient";
import {rootServiceURL} from "../../Controller";
import {ISurveyQuestion} from "../../interfaces/ISurveyQuestion";
import {IField} from "../../interfaces/IField";
import {deleteAnswer} from "./actions";
import {IChartType} from "../../interfaces/IChartType";
import {IQuestionType} from "../../interfaces/IQuestionType";
import SelectRange from "./SelectRange"

// Define la unión de tipos para la prop question
type QuestionProp = ISurveyQuestion | ISurveyItem;

interface Props {
    question: QuestionProp;
    loadQuestion: () => void; // Define el tipo de la función loadQuestion
}

export default function SurveyQuestionDetail({ question, loadQuestion }: Props){

    const [form] = Form.useForm();
    const [answersForm] = Form.useForm();
    const dispatch = useDispatch();

    const questionid = question.persistibleitemid;
    // @ts-ignore
    const auth = useSelector(state => state.auth);
    const [list, setList] = useState<IAnswer[]>([])
    const [chartTypeList, setChartTypeList] = useState<IChartType[]>([])
    const [questionTypeList, setQuestionTypeList] = useState<IQuestionType[]>([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [updateFieldValues, setUpdateFieldValues] = useState<ISurveyQuestion | undefined>(undefined);
    const [answersUpdateFieldValues, setAnswersUpdateFieldValues] = useState<IAnswer | undefined>(undefined);

    useEffect(() => {
        loadEnums();
        updateSurveyQuestionForm();
        loadAnswers();
    }, [question])

    const updateSurveyQuestionForm = () => {
        cleanFormData();

        //@ts-ignore
        const updatedFieldValues :ISurveyQuestion= {};

        Object.keys(question)?.forEach(field => {

            //@ts-ignore
            updatedFieldValues[field] = question[field];

            //@ts-ignore
            form.setFieldValue(field, question[field]);
        });
        console.log(updatedFieldValues)
        setUpdateFieldValues(updatedFieldValues);
    }

    const loadEnums = () => {
        const token = auth.token;

        const restClient = new RestClient(dispatch)
            .token(token).url(rootServiceURL() +"phocusservice/charttype");

        restClient.doGet((result:IChartType[]) => {
            result && setChartTypeList(result)
        })

        restClient.url(rootServiceURL() +"phocusservice/questiontype")
        restClient.doGet((result:IQuestionType[]) => {
            result && setQuestionTypeList(result)
        })
    }

    const loadAnswers = () => {
        const token = auth.token;

        const restClient = new RestClient(dispatch)
            .token(token).url(rootServiceURL() +"phocusservice/answersByQuestion/" + questionid);

        restClient.doGet((result:[IAnswer]) => {
            result && setList(result)
        })
    }

    const columns : IField<IAnswer>[]=
        [
            {
                title: 'Código',
                dataIndex: 'answerid',
                key: 'answerid',
                show:false,
                update:false,
                sorter: (a, b) => a.answerid > b.answerid ? 1 : -1,
            },
            {
                title: 'Orden',
                dataIndex: 'answerorder',
                key: 'answerorder',
                show:true,
                update:true,
                rules: [{ required: true, message: 'Please input caption' }],
                sorter: (a, b) => a.answerorder > b.answerorder ? 1 : -1,
            },
            {
                title: 'Valor',
                dataIndex: 'caption',
                key: 'caption',
                show:true,
                update:true,
                rules: [{ required: true, message: 'Please input caption' }],
                sorter: (a, b) => a.caption > b.caption ? 1 : -1,
            },
            {
                title: 'Actions',
                key: 'actions',
                render: (value, record) => {
                    return (
                        <Space size="middle">
                            <a onClick={() => openUpdateModal(record)
                            }>Editar </a>
                            <Popconfirm
                                placement="left"
                                title="Eliminar Respuesta"
                                description={<>
                                    <p>
                                        "¿Está seguro de que quiere eliminar esta Respuesta?"
                                    </p>

                                </>}
                                onConfirm={() => {
                                    deleteAnswer(record.answerid, auth, dispatch, () => {loadAnswers()})
                                }}
                                onCancel={() => {}}
                                okText="Si"
                                cancelText="No"
                            >
                                <Button>Eliminar</Button>
                            </Popconfirm>
                        </Space>
                    );
                },
            }
        ];

    const openUpdateModal = (entity: IAnswer) => {
        cleanAnswersFormData();

        //@ts-ignore
        const updatedFieldValues :IAnswer= {};

        Object.keys(entity)?.forEach(field => {

            //@ts-ignore
            updatedFieldValues[field] = entity[field];

            //@ts-ignore
            answersForm.setFieldValue(field, entity[field]);
        });
        setAnswersUpdateFieldValues(updatedFieldValues);
        setIsModalOpen(true)
    }

    const cleanAnswersFormData = () => {
        answersForm.resetFields();
        setAnswersUpdateFieldValues(undefined);
    }

    const cleanFormData = () => {
        form.resetFields();
        setUpdateFieldValues(undefined);
    }

    const onFinish = (values : ISurveyQuestion) => {
        let entityToPost = {
            ...updateFieldValues,
            ...values,
            blockid: question.blockid ? {blockid: question.blockid.blockid} : null
        };

        const url = rootServiceURL() + 'phocusservice/surveyquestion';

        const token = auth.token;

        const rc = new RestClient(dispatch).token(token).url(url);

        rc.doPost(entityToPost, (result: any) => {
            if (rc.isSuccess()) {
                message.success("Pregunta actualizada correctamente");
                loadQuestion();
            }
            else{
                message.error( "Error actualizando Pregunta "+rc.getErrors());
            }

        }, false);
    }

    const onFinishAnswers = (values : IAnswer) => {
        let entityToPost = {
            ...answersUpdateFieldValues,
            ...values
        };

        const url = rootServiceURL() + 'phocusservice/answer';

        const token = auth.token;

        const rc = new RestClient(dispatch).token(token).url(url);

        const update = answersUpdateFieldValues && answersUpdateFieldValues!.answerid !== undefined && answersUpdateFieldValues!.answerid > 0;
        if(!update){
            let newEntityToPost = {
                ...entityToPost,
                possibleanswersid: {surveyquestionid: {persistibleitemid: questionid}}
            }
            console.log("Creando Respuesta: " ,newEntityToPost)

            rc.doPost(newEntityToPost, (result: any) => {
                if (rc.isSuccess()) {
                    message.success("Respuesta creada correctamente")
                    loadAnswers();
                    setIsModalOpen(false);
                    cleanAnswersFormData();
                }
                else{
                    message.error( "Error creando Respuesta "+rc.getErrors());
                }

            }, false);

        } else {
            let newEntityToPost = {
                ...entityToPost,
                possibleanswersid: {surveyquestionid: {persistibleitemid: questionid}}
            }
            rc.doPost(newEntityToPost, (result: any) => {
                if (rc.isSuccess()) {
                    message.success("Respuesta actualizada correctamente");
                    loadAnswers();
                    setIsModalOpen(false);
                    cleanAnswersFormData();
                }
                else{
                    message.error( "Error actualizando Respuesta "+rc.getErrors());
                }

            }, false);
        }
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    return (
        <>
            <Form
                name="basic"
                form={form}
                initialValues={{ remember: false }}
                onFinish={(values) => onFinish(values)}
                scrollToFirstError
                autoComplete="off"
                style={{margin: '20px'}}
            >
                <Form.Item
                    labelCol={{ span: 24 }}
                    label={"Título"}
                    name={"caption"}
                    rules={[{ required: true, message: 'Please input title' }]}
                >
                    <Input.TextArea
                        autoSize={{ minRows: 1, maxRows: 5 }} // Ajusta las filas mínimas y máximas
                        style={{ width: '100%', resize: 'none' }} // Evita que se redimensione horizontalmente
                    />
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 24 }}
                    label={"Tipo de Gráfico"}
                    name={"chartType"}
                >
                    <Select
                    options={chartTypeList.map(chartType => {
                        return {
                            label: chartType.display,
                            value: chartType.value
                        }
                    })}/>
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 24 }}
                    label={"Tipo de Pregunta"}
                    name={"questionType"}
                >
                    <Select
                        options={questionTypeList.map(questionType => {
                            return {
                                label: questionType.display,
                                value: questionType.value
                            }
                        })}/>
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 24 }}
                    label={"Obligatorio"}
                    name={"mandatory"}
                    valuePropName="checked"
                >
                    <Checkbox/>
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 24 }}
                    label={"Restringido solo a las respuestas posibles"}
                    name={"restrictAnswerToPossibleOnly"}
                    valuePropName="checked"
                >
                    <Checkbox/>
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 24 }}
                    label={"Respuesta Multiple"}
                    name={"multiple"}
                    valuePropName="checked"
                >
                    <Checkbox/>
                </Form.Item>

                {"multiple" in question && question.multiple ?
                    <>
                        <Form.Item
                            key={"numanswers"}
                            labelCol={{ span: 24 }}
                            label={"Número de respuestas"}
                            name={"numanswers"}
                        >
                            <InputNumber min={1}/>
                        </Form.Item>
                        <Form.Item
                        labelCol={{ span: 24 }}
                        label={"Exacto"}
                        name={"numanswersexact"}
                        valuePropName="checked"
                        >
                            <Checkbox/>
                        </Form.Item>
                    </>
                        :<></>}

                <Button type="primary" htmlType="submit">
                    Actualizar
                </Button>
            </Form>
            {//@ts-ignore
                "questionType" in question ? question.questionType == "QUESTION_TYPE_SELECT_RANGE"  ?
                    <SelectRange question={question} loadQuestion={loadQuestion}></SelectRange>
                    : //@ts-ignore
                    question.questionType == "QUESTION_TYPE_SELECT" ?
                    <>
                        <Modal
                            title={"Modificar Respuesta"}
                            open={isModalOpen}
                            footer={null}
                            onCancel={() => {
                                cleanFormData();
                                setIsModalOpen(false); }}>
                            <Form
                                name="basic"
                                form={answersForm}
                                initialValues={{ remember: false }}
                                onFinish={(values) => onFinishAnswers(values)}
                                scrollToFirstError
                                autoComplete="off">
                                {columns.filter(field =>(field.update)).map(field => (
                                    <Form.Item
                                        labelCol={{ span: 24 }}
                                        label={""+field.title}
                                        name={field.key as string}
                                        rules={field.rules}
                                        valuePropName={field.valuePropName}
                                    >
                                        {field.renderInput ? field.renderInput(field.key as string) : <Input/>}
                                    </Form.Item>
                                ))}
                                <Button type="primary" htmlType="submit">
                                    Crear / Actualizar
                                </Button>
                            </Form>
                        </Modal>
                        <Button type="primary" onClick={showModal} style={{margin: '20px'}}>
                            Crear nueva Respuesta
                        </Button>
                        <PhocusTable columns={columns} dataSource={list} />
                    </>
                        :
                        ""
                    :""}
        </>
    );
}